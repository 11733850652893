import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import moment from 'moment';
import {
  Button,
  Description,
  formatCurrency,
  Modal,
  Spin,
  Tag,
} from 'tera-dls';

import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import UserApi from '../../api';
import {
  PROMOTION_STATUS,
  PROMOTION_STATUS_COLOR,
  PROMOTION_TYPE,
} from '../../constants';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const UserDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết mã ưu đãi'}
        closeIcon={false}
        width={500}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <Description label="Mã ưu đãi">{detail?.code}</Description>
          <Description label="Đã sử dụng">{detail?.used_count}</Description>
          <Description label="Giảm giá theo tiền">
            {detail?.discount_by_money &&
              formatCurrency(detail?.discount_by_money)}
          </Description>
          <Description label="Giảm giá theo %">
            {detail?.discount_by_percentage &&
              formatCurrency(detail?.discount_by_percentage)}
          </Description>
          <Description label="Kiểu giảm giá">
            {PROMOTION_TYPE[detail?.type]}
          </Description>
          <Description label="Trạng thái sử dụng">
            <Tag color={PROMOTION_STATUS_COLOR[detail?.status]}>
              {PROMOTION_STATUS[detail?.status]}
            </Tag>
          </Description>
          <Description label="Ngày hết hạn">
            {detail?.date_expire &&
              moment(detail?.date_expire).format(DATE_FORMAT)}
          </Description>
          <Description label="Người tạo">
            <HoverQuickView
              // avatarUrl={submitter?.avatar_url}
              // email={submitter?.email}
              // phone={submitter?.phone}
              // code={submitter?.code}
              name={detail?.user_created?.full_name}
            >
              <span className="line-clamp-1 text-blue-600">
                {detail?.user_created?.full_name}
              </span>
            </HoverQuickView>
          </Description>
          <Description label="Ngày tạo">
            {moment(detail?.created_at).format(DATE_TIME_FORMAT)}
          </Description>
          {detail?.user_updated && (
            <>
              <Description label="Người cập nhật">
                <HoverQuickView
                  // avatarUrl={submitter?.avatar_url}
                  // email={submitter?.email}
                  // phone={submitter?.phone}
                  // code={submitter?.code}
                  name={detail?.user_updated?.full_name}
                >
                  <span className="line-clamp-1 text-blue-600">
                    {' '}
                    {detail?.user_updated?.full_name}
                  </span>
                </HoverQuickView>
              </Description>

              <Description label="Ngày cập nhật">
                {moment(detail?.updated_at).format(DATE_TIME_FORMAT)}
              </Description>
            </>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default UserDetail;
