import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const LecturerApplicationEndpoint = `${endpoint}/bake-teach/lecturer/register`;
const LecturerApplicationApi = {
  getList: async (params) =>
    await api
      .get(`${LecturerApplicationEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${LecturerApplicationEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  register: async ({ params }) =>
    await api
      .post(`${LecturerApplicationEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${LecturerApplicationEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${LecturerApplicationEndpoint}/delete/${id}`)
      .then((result) => result?.data),
  approve: async ({ id, params }) =>
    await api
      .put(`${LecturerApplicationEndpoint}/approve/${id}`, params)
      .then((result) => result?.data),
  reject: async ({ id, params }) =>
    await api
      .put(`${LecturerApplicationEndpoint}/reject/${id}`, params)
      .then((result) => result?.data),
};
export default LecturerApplicationApi;
