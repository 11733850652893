import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import CkEditor, {
  convertClassNameToInlineStyle,
} from '_common/component/CkEditor';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import UploadFiles from '_common/dof/UploadFiles';
import { usePrompt } from '_common/hooks/usePrompt';
import _ from 'lodash';
import moment from 'moment';
import { CardContent } from 'pages/SaleManagement/Product/containers/Form';
import { GENDER_TYPE } from 'pages/UserManagement/User/constants';
import InputData from 'pages/UserManagement/User/containers/RegistrationForm/InputData';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Col,
  notification,
  Row,
  Spin,
} from 'tera-dls';
import { default as LecturerApi, default as ProductApi } from '../../api';
import { LECTURER_URL } from '../../url';

const LecturerForm = () => {
  const navigate = useNavigate();
  const form = useForm({ mode: 'onChange' });
  const [files, setFiles] = useState<any>([]);
  const [degree, setDegree] = useState<any>([]);

  const {
    formState: { isDirty },
  } = form;

  const { id } = useParams();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<any>();
  const [editor, setEditor] = useState<any>();

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(['get-lecturer-detail', id], () => LecturerApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, [
        'full_name',
        'address',
        'phone',
        'email',
        'type',
        'gender',
      ]);
      form.reset({
        ...data,
        is_active: Boolean(detail?.is_active),
        ...(detail.birthday && { birthday: moment(detail.birthday) }),
      });
      setEditor(detail?.information);
      setImage({ url: detail?.avatar });
      setDegree(detail?.degree);
      if (detail?.attachments_lecturer) {
        setFiles(
          detail?.attachments_lecturer?.map((i) => ({
            id: i?.id,
            name: i?.name,
            url: i?.url,
          })),
        );
      }
    }
  }, [detail]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) => ProductApi.update(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-lecturer-list']);
          queryClient.invalidateQueries(['get-lecturer-detail']);
          notification.success({
            message: res?.msg,
          });
          form.reset();
          setTimeout(() => {
            navigate(-1);
          }, 200);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        ...(values?.birthday && {
          birthday: values?.birthday.format(DATE_BACKEND_FORMAT),
        }),
        avatar: image?.url ?? null,
        degree,
        attachment: files,
        information: convertClassNameToInlineStyle(
          (window as any).editor ? (window as any).editor.getData() : null,
        ),
        user_id: id,
      },
      ...(id && { id }),
    });
  };

  const genderOptions = Object.entries(GENDER_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div className="tera-page-form !gap-0">
      <div className="page-header-sticky bg-[#FAFAF9]">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              containerItemClassName="text-sm"
              items={[
                {
                  title: (
                    <a className="cursor-pointer text-blue-400 italic ">
                      Danh sách giảng viên
                    </a>
                  ),
                  onClick: () => navigate(LECTURER_URL.list.path),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px]">
                      {id ? 'Sửa giảng viên' : 'Thêm giảng viên'}
                    </a>
                  ),
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function">
            <ButtonCommon
              htmlType="submit"
              type="success"
              className="page-header-btn bg-green-500"
              icon={<BookmarkOutlined className="w-4" />}
              onClick={form.handleSubmit(handleSubmitForm)}
              loading={isLoading}
              disabled={isLoading}
            >
              Lưu
            </ButtonCommon>
          </div>
        </div>
      </div>
      <div className="page-content-v2 bg-white shadow-xsm !rounded-xl px-2.5 h-full mx-2.5 mb-2.5 !gap-y-0">
        <Spin spinning={!!id && isFetching}>
          <div className="divide-y-[1px] divide-dashed">
            <Row className="grid-cols-2 xmd:grid-cols-3 gap-5 pb-5">
              <Col className="col-span-2">
                <CardContent title="Tài liệu đính kèm">
                  <FormTera
                    form={form}
                    isLoading={false}
                    onSubmit={handleSubmitForm}
                  >
                    <div className="grid grid-cols-2 gap-5">
                      <div className="col-span-1 space-y-3">
                        <FormTeraItem label="Ảnh đại diện" name="">
                          <ImageBackground
                            value={image}
                            onChange={(val) => {
                              setImage(val);
                            }}
                            isShowBtnDelete
                            imageClassName="!size-[100px] object-cover"
                            object_key={'course-category'}
                            folder={'bake-teach'}
                          />
                        </FormTeraItem>
                        <FormTeraItem
                          label="Họ và tên"
                          name="full_name"
                          rules={[
                            {
                              required: messageValidate.emptyText,
                            },
                          ]}
                        >
                          <Input maxLength={255} />
                        </FormTeraItem>
                        <FormTeraItem label="Ngày sinh" name="birthday">
                          <DatePicker placeholder={'Vui lòng chọn'} />
                        </FormTeraItem>
                        <FormTeraItem label="Địa chỉ" name="address">
                          <Input maxLength={255} />
                        </FormTeraItem>
                      </div>
                      <div className="col-span-1 space-y-3">
                        <FormTeraItem label="Số điện thoại" name="phone">
                          <Input disabled={!!id} />
                        </FormTeraItem>
                        <FormTeraItem label="Giới tính" name="gender">
                          <Select
                            allowClear={false}
                            placeholder="Vui lòng chọn"
                            options={genderOptions}
                          />
                        </FormTeraItem>
                        <FormTeraItem label="Email liên hệ" name="email">
                          <Input maxLength={320} />
                        </FormTeraItem>
                        <FormTeraItem label="Bằng cấp">
                          <InputData data={degree} updateData={setDegree} />
                        </FormTeraItem>
                      </div>
                    </div>
                  </FormTera>
                </CardContent>
              </Col>
              <Col className="grid-cols-1 col-span-3 xmd:col-span-1 pointer-event-none">
                <CardContent title="Tài liệu đính kèm">
                  <UploadFiles
                    fileList={files}
                    isCount={false}
                    maxFileNumber={10}
                    maxSize={10}
                    folder="lecturer"
                    object_id="lecturer"
                    accept="application/pdf"
                    onReceiveFiles={(_, files) => setFiles(files)}
                    onRemove={(fileDelete) => {
                      const newList = files.filter(
                        (file) => file.id !== fileDelete?.id,
                      );
                      setFiles(newList);
                    }}
                  />
                </CardContent>
              </Col>
            </Row>
            <Row className="pt-5">
              <CardContent title="Mô tả giảng viên">
                <CkEditor
                  data={editor}
                  editorCallback={(editor) =>
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        'height',
                        '500px',
                        editor.editing.view.document.getRoot(),
                      );
                    })
                  }
                />
              </CardContent>
            </Row>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default LecturerForm;
