import CardDetail from '_common/component/CardDetail';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import {
  ORDER_STATUS,
  ORDER_STATUS_COLOR,
} from 'pages/SaleManagement/SaleOrder/constants';
import customTwMerge from 'tailwind-merge.config';
import { Description, formatDate, Row, Tag } from 'tera-dls';

const DescriptionCustom = ({ label, children, className }) => {
  return (
    <Description
      label={label}
      className={customTwMerge('grid-cols-5 mb-0', className)}
      childrenClassName="col-span-4"
    >
      {children}
    </Description>
  );
};
const CustomerInfo = ({ dataDetail }) => {
  const renderInfo = [
    {
      title: 'Họ và tên',
      value: (
        <HoverQuickView
          name={dataDetail?.student?.full_name}
          avatarUrl={dataDetail?.student?.avatar}
        >
          <span className="line-clamp-1 text-blue-600">
            {dataDetail?.student?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số đơn hàng',
      value: <span className="text-blue-600">{dataDetail?.code}</span>,
    },
    {
      title: 'Số điện thoại',
      value: dataDetail?.phone,
    },
    {
      title: 'Ngày tạo đơn',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },

    {
      title: 'Địa chỉ giao hàng',
      value: dataDetail?.shipping_address,
    },
    {
      title: 'Trạng thái',
      value: (
        <Tag color={ORDER_STATUS_COLOR[dataDetail?.status]}>
          {ORDER_STATUS[dataDetail?.status]}
        </Tag>
      ),
    },
    {
      title: 'Ghi chú',
      value: dataDetail?.staff_note,
      className: 'col-start-2',
    },
  ];
  return (
    <CardDetail title="Thông tin đơn hàng" className="h-full">
      <Row className={customTwMerge('grid-cols-1 xl:grid-cols-2 gap-2.5')}>
        {renderInfo.map(({ title, value, className }) => (
          <div>
            <DescriptionCustom
              label={title}
              className={customTwMerge(className)}
            >
              {value}
            </DescriptionCustom>
          </div>
        ))}
      </Row>
    </CardDetail>
  );
};

export default CustomerInfo;
