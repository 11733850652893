import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PROMOTION_TYPE } from '../../constants';
import Select from '_common/dof/Control/Select';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function CourseCategoryFilter({
  open,
  initialValue,
  onClose,
  onFilter,
}: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...(values?.time?.[0] && {
        start_date: values?.time?.[0]?.format(DATE_BACKEND_FORMAT),
        end_date: values?.time?.[1]?.format(DATE_BACKEND_FORMAT),
      }),
      type: values?.type,
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      time: null,
      start_date: null,
      end_date: null,
      type: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset({
      type: initialValue?.type,
      ...(initialValue?.start_date &&
        initialValue?.end_date && {
          time: [
            moment(initialValue?.start_date),
            moment(initialValue?.end_date),
          ],
        }),
    });
  }, [initialValue]);

  const promotionType = Object.entries(PROMOTION_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Thời gian hết hạn" name="time">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Kiểu giảm giá" name="type">
          <Select
            allowClear={false}
            placeholder="Vui lòng chọn"
            options={promotionType as any}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default CourseCategoryFilter;
