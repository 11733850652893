import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import ActionCUD from '_common/component/TableColumnCustom/ActionCUD';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  MAXIMUM_CURRENCY,
  MAXIMUM_QUANTITY,
  REGEX,
} from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Radio from '_common/dof/Control/Radio';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { get } from 'lodash';
// import { observer } from 'mobx-react-lite';

import SelectUserAddress from '_common/dof/Select/SelectUserAddress';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import UserAddressApi from 'pages/UserManagement/User/api/address';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  ChevronDownOutlined,
  Col,
  Image,
  InputNumber as InputNumberTera,
  Row,
  formatCurrency,
  notification,
} from 'tera-dls';
import SaleOrderApi from '../../api';
import { discountType } from '../../constants';
import ProductModal from './ProductModal';
import StudentModal from './StudentModal';

export interface ISaleOrderFormContentRef {
  onSave: (status: string) => void;
  onSaveAndCreate: (status: string) => void;
}
export type SaleOrderFormContentField = 'student';

interface IFormProps {
  id: number | string;
  dataDetails?: any;
  onSuccess?: () => void;
  createParamsApi?: any;
  initialValues?: any;
  hiddenFields?: Array<SaleOrderFormContentField>;
  loading?: boolean;
}

const SaleOrderFormContent = (props: IFormProps, ref) => {
  const { id, dataDetails, onSuccess, hiddenFields = [], loading } = props;
  const defaultValues = {
    note: '',
    order_date: moment(),
    created_at: moment(),
    email: '',
    phone: '',
    shipping_fee: 0,
    discount_type: 'not_available',
  };
  const navigate = useNavigate();

  const newForm = useForm<any>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const [student, setStudent] = useState<any>();
  const [openStudentModal, setOpenStudentModal] = useState<boolean>(false);
  const [openProductModal, setOpenProductModal] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState([]);
  const [final_total, setFinalTotal] = useState(0);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [statusSave, setStatusSave] = useState<string>('');

  const [shipping_charge, setShippingCharge] = useState(0);

  const watchShippingType = newForm.watch('shipping_type');

  useEffect(() => {
    if (watchShippingType === 'shop') setShippingCharge(0);
  }, [watchShippingType]);

  const [discount, setDiscount] = useState({
    type: null,
    amount: 0,
    value: 0,
  });

  const tableRef = useRef(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleReset = () => {
    newForm.reset(defaultValues);
    setDataSource([]);
    setStudent({});
    setDiscount({
      type: null,
      amount: 0,
      value: 0,
    });
    setStatusSave(undefined);
    setFinalTotal(0);
  };

  const { data: address } = useQuery(
    ['get-user-address-default', student?.id],
    () =>
      UserAddressApi.getList({
        page: 1,
        limit: 10,
        user_id: student?.id,
        is_default: 1,
      }),
    {
      enabled: !!student?.id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (!!address?.data?.[0]) {
      newForm.setValue('user_address_id', address?.data?.[0]?.id);
    }
  }, [address]);

  const { mutate: mutateSaleOrder, isLoading: isLoadingMutate } = useMutation(
    (variables: any) => {
      const paramStatus = {
        ...variables,
        status: statusSave,
      };
      if (id)
        return SaleOrderApi.update({
          id,
          params: paramStatus,
        });

      return SaleOrderApi.create({
        params: paramStatus,
      });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-sale-order-list']);
          queryClient.invalidateQueries(['get-sale-order-detail']);
          handleReset();
          notification.success({
            message: res?.msg,
          });
          if (onSuccess) {
            onSuccess();
            return;
          }
          if (isRedirect) {
            navigate(-1);
          }
          setIsRedirect(false);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDeleteRow = (key, title) => {
    confirm.warning({
      title: 'Xóa sản phẩm đơn mua hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn xóa sản phẩm đơn mua hàng</p>
          <p>
            <strong>{title}</strong> này không?
          </p>
        </div>
      ),
      onOk: () => {
        tableRef?.current?.deleteRow(key);
      },
    });
  };

  const discount_type = newForm?.watch('discount_type');

  useEffect(() => {
    if (!dataSource) return;

    const total = dataSource.reduce((acc, val) => {
      const result =
        Number(acc) +
        Number(get(val, 'quantity')) * Number(get(val, 'unit_price'));
      return result;
    }, 0);

    setFinalTotal(total);

    let discountValue = discount.value;

    if (discount.type === 'percentage') {
      const newDiscountValue = Number(total) * (Number(discount.amount) / 100);
      if (newDiscountValue !== discount.value)
        setDiscount({ ...discount, value: Number(newDiscountValue) });
    }
    if (discountValue > total) {
      discountValue = Number(total);
      if (discount.type === 'fixed')
        setDiscount({
          ...discount,
          value: discountValue,
          amount: discountValue,
        });
    }
  }, [dataSource, discount, shipping_charge]);

  const products = get(dataDetails, 'products');

  useEffect(() => {
    if (!products) return;
    setDataSource(products);
  }, [products]);

  useEffect(() => {
    if (!dataDetails) return;
    newForm.reset({
      ...dataDetails,
      order_date: moment(dataDetails?.order_date),
      created_at: moment(dataDetails?.created_at),
    });
    dataDetails?.student && setStudent(dataDetails?.student);
  }, [newForm, dataDetails]);

  const handleChangeShippingCharge = (value) => {
    setShippingCharge(value);
  };

  const optionsDelivery = Object.entries([]).map(([key, value]) => ({
    key: key,
    label: value,
    value: key,
  }));

  useEffect(() => {
    if (!id) handleReset();
  }, [id]);

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{record?.name_product ?? val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 120,
      render: (val, record) => record?.name_category ?? val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 150,
      render: (price) => formatCurrency(Number(price)),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      editable: true,
      type: 'int',
      inputProps: {
        min: 1,
        max: MAXIMUM_QUANTITY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
      },
      width: 120,
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 60,
      fixed: 'right',
      render: (_, record) => {
        return (
          <ActionCUD
            size="small"
            onClickDelete={() => handleDeleteRow(record?.id, record?.title)}
          />
        );
      },
    },
  ];

  const handleSubmitForm = (values) => {
    if (!dataSource || dataSource?.length == 0) {
      notification.error({
        message: 'Vui lòng chọn ít nhất 1 sản phẩm',
      });
      return;
    }
    const newData = {
      ...values,
      student_id: student?.id,
      order_date: moment(values?.order_date).format(DATE_BACKEND_FORMAT),
      note: values?.note,
      discount_type: values?.discount_type,
      // !== 'not_available'
      //   ? values?.discount_type
      //   : undefined,
      discount_value: discount?.amount,
      shipping_type: values?.shipping_type,
      shipping_fee: shipping_charge,
      products: dataSource?.map((i) => ({
        ...i,
        product_id: i?.id,
        name_product: i?.name_product ?? i?.name,
        image_product: i?.image_product ?? i?.image_url,
        name_category: i?.name_category ?? i?.category?.name,
      })),
      final_total: final_total,
      status: statusSave,
    };
    mutateSaleOrder(newData);
  };

  const handleSave = (status) => {
    if (isLoadingMutate) return;
    setIsRedirect(true);

    if (status) {
      setStatusSave(status);
    }
    setTimeout(() => {
      newForm.handleSubmit(handleSubmitForm)();
    }, 0);
  };

  const handleSaveAndCreate = (status) => {
    if (isLoadingMutate) return;
    if (status) {
      setStatusSave(status);
    }
    newForm.handleSubmit(handleSubmitForm)();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onSave: handleSave,
        onSaveAndCreate: handleSaveAndCreate,
      };
    },
    [ref, handleSave, handleSaveAndCreate],
  );

  const handleChangeDiscount = (value, type?: string) => {
    if (!type) return;

    let discountValue = 0;
    if (type === 'fixed') {
      discountValue = value;
    }

    if (type === 'percentage') {
      discountValue = Number(final_total) * (Number(value) / 100);
    }

    setDiscount({
      type: type ? type : discount.type,
      amount: Number(value),
      value: Number(discountValue),
    });
  };

  return (
    <>
      <FormTera form={newForm} object_type="crm" onSubmit={handleSubmitForm}>
        <CardFormV2
          title="Thông tin đơn bán hàng"
          id="information"
          className="form-section"
        >
          <Row className={'grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0'}>
            <FormTeraItem
              label="Học viên"
              name=""
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Input
                placeholder="Vui lòng chọn"
                className="w-full cursor-pointer"
                onClick={() => {
                  setOpenStudentModal(true);
                }}
                disabled={hiddenFields?.includes('student')}
                suffix={<ChevronDownOutlined className="w-4 cursor-pointer" />}
                value={get(student, 'full_name', '')}
              />
            </FormTeraItem>
            <FormTeraItem label="Số điện thoại" name="phone">
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <FormTeraItem
              label="Email"
              name="email"
              rules={[
                {
                  pattern: {
                    value: REGEX.EMAIL,
                    message: messageValidate.email,
                  },
                },
              ]}
            >
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <FormTeraItem label="Ngày tạo đơn" name="created_at">
              <DatePicker
                placeholder="Vui lòng chọn"
                format={DATE_FORMAT}
                disabled
              />
            </FormTeraItem>
            <FormTeraItem label="Ngày bán hàng" name="order_date">
              <DatePicker
                placeholder="Vui lòng nhập"
                format={DATE_FORMAT}
                allowClear={false}
              />
            </FormTeraItem>

            <Col className={'col-span-2 xl:col-span-3'}>
              <FormTeraItem label="Ghi chú" name="note">
                <TextArea placeholder="Vui lòng nhập" rows={10} />
              </FormTeraItem>
            </Col>
          </Row>
        </CardFormV2>
        <CardFormV2 title="Sản phẩm" id="product" className="form-section">
          <Button
            htmlType="button"
            className="mb-5 ml-auto"
            onClick={() => {
              setOpenProductModal(true);
            }}
            disabled={!get(student, 'id')}
          >
            Thêm nhiều sản phẩm
          </Button>
          <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
            <TableTera
              actionRef={tableRef}
              columns={columns}
              mode="editable-cell"
              className="center-table"
              loading={loading}
              data={dataSource}
              rowKey={'id'}
              pagination={{}}
              editable={{
                onValuesChange: (_, list) => {
                  const newList = list?.filter((item) => !item?.isDelete);
                  setDataSource(newList);
                },
              }}
            />
          </div>
        </CardFormV2>

        <CardFormV2 title="Chiết khấu" id="tax" className="form-section">
          <Row className={'grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0'}>
            <FormTeraItem label="Chiết khấu" name="discount_type">
              <Select
                allowClear
                placeholder="Vui lòng chọn"
                options={Object.keys(discountType).map((key) => ({
                  value: key,
                  label: discountType[key],
                }))}
                onChangeCustom={(value) => {
                  setDiscount({ amount: 0, value: 0, type: value });
                }}
              />
            </FormTeraItem>

            <FormTeraItem
              label={
                discount_type === 'percentage'
                  ? 'Phần trăm chiết khấu'
                  : 'Số tiền chiết khấu'
              }
              name="discount_value"
            >
              <InputNumberTera
                min={0}
                max={discount_type === 'by_percentage' ? 100 : final_total}
                placeholder="Vui lòng nhập"
                disabled={!discount_type || discount_type === 'not_available'}
                onBlur={(value) =>
                  handleChangeDiscount(value.target.value, discount.type)
                }
                value={Number(discount.amount)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </FormTeraItem>
          </Row>
        </CardFormV2>

        <div className="text-right">
          <div className="total-money-row">
            <span className="font-semibold">Tạm tính: </span>
            <span className="text-xl font-semibold text-green-500">
              +{formatCurrency(final_total)}
            </span>
          </div>
          {discount.value > 0 && (
            <div className="total-money-row">
              <span className="font-semibold">Chiết khấu: </span>
              <span className="text-xl font-semibold text-green-500">
                -{formatCurrency(discount.value)}
              </span>
            </div>
          )}
          {shipping_charge > 0 && (
            <div className="total-money-row">
              <span className="font-semibold">Phí vận chuyển: </span>
              <span className="text-xl font-semibold text-green-500">
                +{formatCurrency(shipping_charge)}
              </span>
            </div>
          )}

          <div className="total-money-row">
            <span className="font-semibold">Thành tiền: </span>
            <span className="text-xl font-semibold text-green-500">
              {formatCurrency(final_total + shipping_charge - discount.value)}
            </span>
          </div>
        </div>
        <CardFormV2 title="Vận chuyển" id="delivery" className="form-section">
          <FormTeraItem name="shipping_type">
            <Radio
              listOption={optionsDelivery}
              inline
              onChangeCapture={(value) => console.log(value)}
            />
          </FormTeraItem>

          <Row className="grid-cols-3">
            <FormTeraItem label="Phí vận chuyển" name="shipping_fee">
              <InputNumber
                min={0}
                max={MAXIMUM_CURRENCY}
                onChange={handleChangeShippingCharge}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </FormTeraItem>
            <FormTeraItem
              label="Địa chỉ giao hàng"
              name="user_address_id"
              rules={[{ required: messageValidate.emptySelect }]}
            >
              <SelectUserAddress
                disabled={!student?.id}
                paramsApi={{
                  user_id: student?.id,
                  include_id: newForm.watch('user_address_id'),
                }}
              />
            </FormTeraItem>
          </Row>
        </CardFormV2>
      </FormTera>
      {openStudentModal && (
        <StudentModal
          open={openStudentModal}
          onClose={() => setOpenStudentModal(false)}
          selectedValue={student}
          onSubmit={(data) => {
            setStudent(data);
            newForm.setValue('phone', data?.phone);
            newForm.setValue('email', data?.email);
          }}
        />
      )}
      {openProductModal && (
        <ProductModal
          open={openProductModal}
          onClose={() => setOpenProductModal(false)}
          selectedValue={dataSource}
          onSubmit={(values = {}) =>
            setDataSource((prev = []) => [
              ...prev,
              ...values?.map((item) => ({ ...item, quantity: 1 })),
            ])
          }
        />
      )}
    </>
  );
};

export default observer(
  forwardRef<ISaleOrderFormContentRef, IFormProps>(SaleOrderFormContent),
);
