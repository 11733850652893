import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { IFormModel } from 'pages/CourseManagement/Course';
import { useState } from 'react';
import no_homework from 'styles/images/no_homework.png';
import customTwMerge from 'tailwind-merge.config';
import {
  DropdownItem,
  getQueryParams,
  StarOutlined,
  updateQueryParams,
} from 'tera-dls';
import StudentEvaluateDetail from './Detail';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import Searching from './Searching';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import HoverQuickView from '_common/component/HoverQuickView';

const StudentEvaluate = ({ dataDetail }) => {
  dataDetail;
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });

  const params = _.pick(queryParams, ['studentEvaluate']);
  const paramsObject = params?.studentEvaluate
    ? JSON.parse(params?.studentEvaluate)
    : {};

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      studentEvaluate: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const data = [
    {
      id: 1,
      name: 'Tất cả',
      count: 1,
    },
    {
      id: 2,
      name: '5 sao',
      count: 1,
    },
    {
      id: 3,
      name: '4 sao',
      count: 1,
    },
    {
      id: 4,
      name: '3 sao',
      count: 1,
    },
    {
      id: 5,
      name: '2 sao',
      count: 1,
    },
    {
      id: 6,
      name: '1 sao',
      count: 1,
    },
  ];

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Tên học viên',
      dataIndex: 'full_name',
      width: 200,
      render: (value) => (
        <HoverQuickView name={value}>
          <span className="line-clamp-1 text-blue-600">{value}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số sao đánh giá',
      dataIndex: 'phone',
      width: 150,
      render: () => (
        <div>
          <div className="flex gap-1 text-[#E3A008] items-center">
            <div className="flex">
              <StarOutlined className="size-4 fill-[#E3A008]" />
              <StarOutlined className="size-4 fill-[#E3A008]" />
              <StarOutlined className="size-4 fill-[#E3A008]" />
              <StarOutlined className="size-4 fill-[#E3A008]" />
              <StarOutlined className="size-4 fill-[#E3A008]" />
            </div>
            Tuyệt vời
          </div>
        </div>
      ),
    },
    {
      title: 'Thời gian đánh giá',
      dataIndex: 'email',
      width: 150,
      render: (val) => moment(val).format(DATE_TIME_FORMAT),
    },

    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];
  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  return false ? (
    <div className="w-full flex justify-center py-5">
      <div className="flex flex-col justify-center gap-[24px]">
        <img src={no_homework} className="size-[100px] ml-2" />
        <p className="text-[#374151] tracking-[1px]">Chưa có đánh giá nào</p>
      </div>
    </div>
  ) : (
    <>
      <div className="space-y-3">
        <div className="flex gap-3 font-[serif] px-2.5">
          <div className="space-y-[5px] gap-[5px] text-center inline-block">
            <span className="font-[serif]">4,5</span>
            <div className="flex gap-2.5 text-[#E3A008]">
              <StarOutlined className="size-3" />
              <StarOutlined className="size-3" />
              <StarOutlined className="size-3" />
              <StarOutlined className="size-3" />
              <StarOutlined className="size-3" />
            </div>
          </div>
          <div>
            <div className="flex gap-[5px]">
              {data?.map((item) => (
                <div
                  key={item?.id}
                  className={customTwMerge(
                    'rounded border border-[#D1D5DB] flex justify-center py-[4px] w-[160px] cursor-pointer',
                    item.id === (paramsObject?.star ?? 1) &&
                      'border-[#1B486A] cursor-default',
                  )}
                  onClick={() => handleUpdateQueryParams({ star: item.id })}
                >
                  {item?.name} ({item?.count})
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pt-2.5 space-y-2.5 rounded shadow">
          <div className="flex justify-end pr-2.5">
            <Searching onSearch={handleSearch} defaultValues={params} />
          </div>
          <TableTera
            // loading={isFetching}
            wrapperClassName="shadow-none"
            rowKey={'id'}
            data={[{ id: 1, full_name: 'Trần Nhựt Thông' }]}
            className="center-table"
            // pagination={{
            //   onChange: handleChangePage,
            //   total: response?.total || 0,
            //   current: response?.current_page,
            //   pageSize: response?.per_page || 10,
            //   to: response?.to,
            //   from: response?.from || 10,
            // }}
            columns={columns}
          />
        </div>
      </div>
      {openFormModel.open && (
        <StudentEvaluateDetail
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
    </>
  );
};

export default StudentEvaluate;
