import UploadFiles from '_common/dof/UploadFiles';
import { IFileUpload } from '_common/interface';
import React, { useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { VideoCameraOutlined, XCircleSolid } from 'tera-dls';
import GoogleDriveVideo from './GoogleDriveVideo';
import NormalVideo from './NormalVideo';
import YoutobeVideo from './YoutobeVideo';

interface IProps {
  value?: IFileUpload;
  onChange?: (val: IFileUpload) => void;
  isShowBtnDelete?: boolean;
  videoClassName?: string;
  isView?: boolean;
  type?: 'uploaded_video' | 'gg_drive_link' | 'youtube_link';
  isReview?: boolean;
  size?: 'normal' | 'small';
  showThumbnail?: boolean;
  [key: string]: any;
}

const VideoBackground = (props: IProps) => {
  const {
    onChange,
    value,
    type = 'uploaded_video',
    isShowBtnDelete,
    videoClassName,
    isReview = true,
    isView = false,
    size = 'normal',
    ...restProps
  } = props;
  const [file, setFile] = useState<IFileUpload>();
  useEffect(() => {
    value && setFile(value);
  }, [value]);

  const handleUploadThumbnail = (file: IFileUpload) => {
    onChange(file);
    setFile(file);
  };

  const handleRemoveThumbnail = () => {
    setFile(null);
    onChange(null);
  };

  const nodeButtonDelete = (fn: () => void) => {
    return (
      <>
        <XCircleSolid
          className="cursor-pointer absolute z-50 w-6 right-[-10px] top-[-8px] fill-red-500"
          onClick={fn}
        />
        <div className="absolute -right-0.5 w-2 h-2 bg-white z-30" />
      </>
    );
  };

  const videoRender = {
    uploaded_video: (
      <NormalVideo
        url={file?.url}
        isView
        isCreate={!isView}
        isReview={isReview}
        size={size}
      />
    ),
    youtube_link: (
      <YoutobeVideo url={file?.url} isView isReview={isReview} size={size} />
    ),
    gg_drive_link: (
      <GoogleDriveVideo
        url={file?.url}
        isView
        isReview={isReview}
        size={size}
      />
    ),
  };

  if (isView) {
    return (
      <>
        <div className={customTwMerge('relative w-fit')}>
          <div className={customTwMerge('rounded-[10px] overflow-hidden')}>
            {videoRender[type]}
          </div>
        </div>
      </>
    );
  }

  // function snapImage(video, url) {
  //   const canvas = document.createElement('canvas');
  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;
  //   canvas
  //     .getContext('2d')
  //     .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //   const image = canvas.toDataURL('image/png');
  //   const success = image.length > 100000;
  //   url;
  //   if (success) {
  //     setFile((prev = {}) => ({ ...prev, thumbnail: image }));
  //   }

  //   return success;
  // }

  // function handleChange(event) {
  //   if (!showThumbnail) return;
  //   const file = event.target.files[0];
  //   const fileReader = new FileReader();

  //   fileReader.onload = function () {
  //     const blob = new Blob([fileReader.result], { type: file.type });
  //     const url = URL.createObjectURL(blob);
  //     const video = document.createElement('video');

  //     const timeupdate = function () {
  //       if (snapImage(video, url)) {
  //         video.removeEventListener('timeupdate', timeupdate);
  //         video.pause();
  //       }
  //     };

  //     video.addEventListener('loadeddata', function () {
  //       if (snapImage(video, url)) {
  //         video.removeEventListener('timeupdate', timeupdate);
  //       }
  //     });

  //     video.addEventListener('timeupdate', timeupdate);
  //     video.preload = 'metadata';
  //     video.src = url;
  //     video.muted = true;
  //     video.playsInline = true;
  //     video.play();
  //   };

  //   fileReader.readAsArrayBuffer(file);
  // }

  return (
    <>
      {file?.url ? (
        <div className={customTwMerge('relative w-fit')}>
          {isShowBtnDelete && nodeButtonDelete(handleRemoveThumbnail)}
          <div className="rounded-[10px] overflow-hidden cursor-pointer bg-gray-200">
            {videoRender[type]}
          </div>
        </div>
      ) : (
        <UploadFiles
          object_id="thumbnail"
          object_key="product"
          folder="product"
          fileList={[file]}
          onReceiveFiles={handleUploadThumbnail}
          isSingle
          maxSize={1000}
          isView={false}
          isCount={false}
          accept=".mp4"
          className="max-w-max"
          // onChangeFile={handleChange}
          {...restProps}
        >
          <button
            className={customTwMerge(
              'w-[100px] h-[100px] border border-dashed border-gray-200 bg-gray-50 rounded-[10px] flex items-center justify-center',
              videoClassName,
            )}
            type="button"
          >
            <VideoCameraOutlined className="w-8 h-8 text-gray-500" />
          </button>
        </UploadFiles>
      )}
    </>
  );
};

export default React.memo(VideoBackground);
