import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  formatCurrency,
  getQueryParams,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_TYPE,
} from '../Course/constants';
import CourseOrderApi from './api';

import { COURSE_ORDER_STATUS } from './constants';
import CourseOrderDetail from './containers/Detail';
import CourseOrderFilter from './containers/Filter';
import CourseOrderForm from './containers/Form';
import Searching from './containers/Searching';

interface IFormModel {
  open: boolean;
  value?: string | number;
}

const CourseOrder = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const queryClient = useQueryClient();

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-order-list', queryParams],
    () =>
      CourseOrderApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-course-order-summary', queryParams],
    () =>
      CourseOrderApi.getSummaryList({
        ...queryParams,
        status: undefined,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseOrderApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-order-list']);
          queryClient.invalidateQueries(['get-course-order-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa đơn khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận xóa đơn khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const { mutate: mutateChangeStatus } = useMutation(
    (variables: any) => {
      if (variables?.status === 'reject')
        return CourseOrderApi.reject({ id: variables?.id });
      return CourseOrderApi.approve({ id: variables?.id });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-order-list']);
          queryClient.invalidateQueries(['get-course-order-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleChangeStatus = (status: 'approve' | 'reject', record): void => {
    const text = {
      approve: 'duyệt',
      reject: 'hủy',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} đơn khóa học`,
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn {text[status]} đơn khóa học</p>
          <p>
            <b>{record?.course?.name}</b> này không
          </p>
        </p>
      ),
      onOk: () => {
        mutateChangeStatus({ status, id: record?.id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: 'Xem',
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },
      ...(record?.status === 'pending'
        ? [
            {
              key: 2,
              label: 'Duyệt',
              onClick: () => handleChangeStatus('approve', record),
            },
            {
              key: 3,
              label: 'Hủy',
              onClick: () => handleChangeStatus('reject', record),
            },
            {
              key: 4,
              label: 'Sửa',
              onClick: () =>
                setOpenFormModel({ open: true, value: record?.id }),
            },
            {
              key: 5,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.course?.name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã đơn',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'student',
      width: 200,
      render: (value) => (
        <HoverQuickView name={value?.full_name}>
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tên khóa học',
      dataIndex: 'course',
      width: 150,
      render: (val) => (
        <span
          className="line-clamp-2  text-blue-600 cursor-pointer inline"
          // onClick={() => setOpenDetailModel({ open: true, value: record?.id })}
        >
          {val?.name}
        </span>
      ),
    },
    {
      title: 'Giảng viên',
      dataIndex: 'lecturer',
      width: 150,
      render: (value) => (
        <HoverQuickView name={value?.full_name}>
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Danh mục khóa học',
      dataIndex: 'category',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Loại đơn',
      dataIndex: 'type',
      width: 150,
      render: (val) => (
        <span className={`${COURSE_METHOD_COLOR[val]}`}>
          {COURSE_METHOD[val]}
        </span>
      ),
    },
    {
      title: 'Loại khóa học',
      width: 150,
      dataIndex: 'type',
      render: (val) => COURSE_TYPE[val],
    },
    {
      title: 'Thành tiền',
      width: 150,
      dataIndex: '',
      render: () => formatCurrency(0),
    },
    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'status',
      render: (status) =>
        status && (
          <Tag color={COURSE_ORDER_STATUS[status]}>
            {COURSE_ORDER_STATUS[status]}
          </Tag>
        ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const tabItems = useMemo(() => {
    const data = Object.keys(COURSE_ORDER_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{COURSE_ORDER_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);
  isFetching;
  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH ĐỀ XUẤT KHUYẾN MÃI"
          onClickButtonAdd={() => setOpenFormModel({ open: true })}
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              wrapperClassName="shadow-none"
              rowKey={'id'}
              // loading={isFetching}
              data={[{ id: 1 }]}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>
      {openFormModel.open && (
        <CourseOrderForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
      {openDetailModel.open && (
        <CourseOrderDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openFilter && (
        <CourseOrderFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default CourseOrder;
