import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';

import { Routers } from './routers';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

const Root = observer(() => {
  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
