import CkEditor from '_common/component/CkEditor';
import ImageBackground from '_common/component/ImageBackground';
import {
  MAXIMUM_CURRENCY,
  MAXIMUM_QUANTITY,
  REGEX,
} from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Radio from '_common/dof/Control/Radio';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseCategory from '_common/dof/Select/SelectCourseCategory';
import SelectLecturer from '_common/dof/Select/SelectLecturer';
import { observer } from 'mobx-react-lite';
import { CardContent } from 'pages/SaleManagement/Product/containers/Form';
import { forwardRef } from 'react';
import { Col, Row } from 'tera-dls';
import { COURSE_TYPE, LINK_COURSE_TYPE } from '../../constants';
import { renderTypeFile } from './Exercise/LectureItem/Form';

const InformationView = ({
  form,
  image,
  setImage,
  typeFile,
  type,
  limited,
  price,
}) => {
  const isOnlineType = type === 'online';
  const information = form.watch('information');

  const courseOptions = Object.entries(COURSE_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const typeOptions = Object.entries(LINK_COURSE_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <div className="divide-y-[1px] divide-dashed text-sm  px-1">
      <Row className="grid-cols-3 gap-5 pb-5">
        <Col className="col-span-3">
          <CardContent title="Tài liệu đính kèm">
            <div className="grid grid-cols-1 xmd:grid-cols-3 gap-5">
              <div className="col-span-1 space-y-3">
                <FormTeraItem label="Ảnh đại diện" name="">
                  <ImageBackground
                    value={image}
                    onChange={(val) => {
                      setImage(val);
                    }}
                    isShowBtnDelete
                    imageClassName="!size-[100px] object-cover"
                    object_key={'course-category'}
                    folder={'bake-teach'}
                  />
                </FormTeraItem>
              </div>
              <div className="col-span-1 space-y-3">
                <FormTeraItem
                  label="Tên khóa học"
                  name="name"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Input max={255} />
                </FormTeraItem>
                <FormTeraItem
                  label="Danh mục khóa học"
                  name="category_id"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <SelectCourseCategory
                    paramsApi={{
                      include_id: form.watch('lecturer_id'),
                    }}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Loại khóa học"
                  name="type"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Select options={courseOptions} />
                </FormTeraItem>
                <FormTeraItem
                  label="Giảng viên"
                  name="lecturer_id"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <SelectLecturer
                    paramsApi={{
                      include_id: form.watch('lecturer_id'),
                    }}
                  />
                </FormTeraItem>
              </div>
              <div className="col-span-1 space-y-3">
                <FormTeraItem
                  label="Giá khóa học"
                  name="price"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <InputNumber
                    max={MAXIMUM_CURRENCY}
                    min={0}
                    onChange={() => {
                      if (!!form.getValues('discount_price')) {
                        form.setValue('discount_price', 0);
                      }
                    }}
                  />
                </FormTeraItem>
                <FormTeraItem label="Giá khuyến mãi" name="discount_price">
                  <InputNumber
                    {...(!!price
                      ? { max: Number(price) }
                      : { max: MAXIMUM_CURRENCY })}
                    min={0}
                  />
                </FormTeraItem>
                <FormTeraItem label="Thời gian khuyến mãi" name="discount_time">
                  <RangePicker placeholder={['Từ ngày', 'Đến ngày']} />
                </FormTeraItem>
                <FormTeraItem
                  name="is_active"
                  label=""
                  className="w-full xmd:leading-[70px]"
                >
                  <CheckBox labelClassName="font-normal text-[13px]">
                    Hoạt động
                  </CheckBox>
                </FormTeraItem>
              </div>
            </div>
          </CardContent>
        </Col>
      </Row>
      <Row className="py-4">
        <CardContent title="Thông tin khóa học">
          <CkEditor
            name="information"
            data={information}
            editorCallback={(editor) =>
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '200px',
                  editor.editing.view.document.getRoot(),
                );
              })
            }
          />
        </CardContent>
      </Row>
      <Row className="py-4">
        <CardContent title="Video học">
          {isOnlineType ? (
            <>
              <FormTeraItem label="Link khóa học" name="link_url">
                <Input max={1000} />
              </FormTeraItem>
              <FormTeraItem
                label="Mật khẩu khóa học"
                name="password"
                rules={[
                  {
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: messageValidate.password,
                    },
                    minLength: {
                      value: 8,
                      message: messageValidate.min_length_password,
                    },
                    maxLength: {
                      value: 16,
                      message: messageValidate.max_length_password,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
            </>
          ) : (
            <>
              <FormTeraItem label="Video bài học" name="link_url_type">
                <Radio
                  inline
                  onChangeCapture={() => {
                    form.setValue('link_url', '');
                  }}
                  className="gap-4"
                  listOption={typeOptions}
                />
              </FormTeraItem>
              <FormTeraItem name="link_url" label="">
                {renderTypeFile[typeFile]}
              </FormTeraItem>
            </>
          )}
        </CardContent>
      </Row>
      <Row className="py-4">
        <CardContent title="Phân loại">
          <FormTeraItem
            name="have_limit_student"
            label=""
            className="w-full mb-5"
          >
            <CheckBox
              labelClassName="font-normal text-[13px]"
              onChange={(val: any) => {
                if (!!val) {
                  form.setValue('limit_student', 1);
                } else {
                  form.setValue('limit_student', null);
                }
              }}
            >
              Giới hạn học viên
            </CheckBox>
          </FormTeraItem>
          {!!limited && (
            <FormTeraItem
              name="limit_student"
              label="Số lượng học viên"
              className="w-full"
            >
              <InputNumber min={1} max={MAXIMUM_QUANTITY} />
            </FormTeraItem>
          )}
        </CardContent>
      </Row>
    </div>
  );
};

export default observer(forwardRef(InformationView));
