import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  MAXIMUM_CURRENCY,
} from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Radio from '_common/dof/Control/Radio';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Radio as RadioTera, Spin } from 'tera-dls';
import PromotionApi from '../../api';
import { PROMOTION_TYPE } from '../../constants';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const PromotionForm = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const { data: suggest, refetch: fetchSuggestCode } = useQuery(
    ['get-promotion-suggest-code'],
    () => PromotionApi.suggestCode(),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !id,
    },
  );

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(['get-promotion-detail', id], () => PromotionApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, [
        'code',
        'type',
        'discount_by_money',
        'discount_by_percentage',
      ]);
      form.reset({
        status: Boolean(detail?.status),
        have_date_expire: Boolean(detail?.have_date_expire),
        date_expire: detail?.date_expire ? moment(detail?.date_expire) : null,
        ...data,
        radio: !!detail?.discount_by_money ? 'a' : 'b',
      });
    } else {
      form.setValue('code', suggest);
      form.setValue('radio', 'a');
      form.setValue('type', 'apply_first_time');
    }
  }, [detail, suggest]);

  useEffect(() => {
    if (!!id) {
      fetchData();
    } else {
      fetchSuggestCode();
    }
  }, [id]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id ? PromotionApi.update(variables) : PromotionApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-promotion-list']);
          queryClient.invalidateQueries(['get-promotion-suggest-code']);
          queryClient.invalidateQueries(['get-promotion-summary']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    const data = _.pick(values, ['code', 'type']);
    mutateAction({
      params: {
        ...data,
        have_date_expire: Number(values?.have_date_expire),
        date_expire: values?.date_expire?.format(DATE_BACKEND_FORMAT),
        status: Number(values?.status ?? 0),
        ...(values?.radio == 'a'
          ? {
              discount_by_money: values?.discount_by_money,
              discount_by_percentage: null,
            }
          : {
              discount_by_percentage: values?.discount_by_percentage,
              discount_by_money: null,
            }),
      },
      ...(id && { id }),
    });
  };

  const promotionType = Object.entries(PROMOTION_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa mã ưu đãi' : 'Thêm mã ưu đãi'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={600}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
      >
        <Spin spinning={!!id && isLoading}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem
              label="Mã ưu đãi"
              name="code"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input maxLength={255} />
            </FormTeraItem>
            <FormTeraItem label="Kiểu giảm giá" name="type">
              <Select
                allowClear={false}
                placeholder="Vui lòng chọn"
                options={promotionType as any}
              />
            </FormTeraItem>
            <FormTeraItem label="" name="status">
              <CheckBox labelClassName="font-normal text-[13px]">
                Hoạt động
              </CheckBox>
            </FormTeraItem>
            <div className="duration-100">
              <FormTeraItem label="" name="have_date_expire">
                <CheckBox labelClassName="font-normal text-[13px]">
                  Ngày hết hạn
                </CheckBox>
              </FormTeraItem>
              {form.watch('have_date_expire') && (
                <FormTeraItem
                  label=""
                  name="date_expire"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Vui lòng chọn"
                    disabled={!form.watch('have_date_expire')}
                  />
                </FormTeraItem>
              )}
            </div>

            <FormTeraItem label="" name="radio">
              <Radio
                onChangeCapture={() => {
                  form.setValue('discount_by_money', null);
                  form.setValue('discount_by_percentage', null);
                }}
              >
                <RadioTera value={'a'}>
                  <span className="text-[13px]">Giảm giá theo tiền</span>
                </RadioTera>

                {form.watch('radio') == 'a' && (
                  <FormTeraItem
                    name="discount_by_money"
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={MAXIMUM_CURRENCY}
                      disabled={form.watch('radio') != 'a'}
                    />
                  </FormTeraItem>
                )}
                <RadioTera value={'b'}>
                  <span className="text-[13px]">Giảm giá theo %</span>
                </RadioTera>

                {form.watch('radio') == 'b' && (
                  <FormTeraItem
                    name="discount_by_percentage"
                    help="< 100"
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      disabled={form.watch('radio') != 'b'}
                    />
                  </FormTeraItem>
                )}
              </Radio>
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default PromotionForm;
