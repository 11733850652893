import ImageBackground from '_common/component/ImageBackground';
import { DATE_FORMAT } from '_common/constants/common';
import UploadFiles from '_common/dof/UploadFiles';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import { CardContent } from 'pages/SaleManagement/Product/containers/Form';
import { GENDER_TYPE } from 'pages/UserManagement/User/constants';
import UserDetail from 'pages/UserManagement/User/containers/Detail';
import { useEffect, useState } from 'react';
import { Col, Row, Tag } from 'tera-dls';
import { IFormModel } from '../..';
import {
  LECTURER_APPLICATION_STATUS,
  LECTURER_APPLICATION_STATUS_COLOR,
} from '../../constants';

const Overview = ({ dataDetail }) => {
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (dataDetail?.attachment) {
      setFiles(
        dataDetail?.attachment?.map((i) => ({
          id: i?.id,
          name: i?.name,
          url: i?.url,
        })),
      );
    }
  }, [dataDetail]);

  const data1 = [
    {
      key: '1',
      title: 'Ảnh đại diện',
      value: (
        <div className="flex justify-start mb-2.5">
          <ImageBackground
            isView
            value={{ url: dataDetail?.user?.avatar }}
            isShowBtnDelete={false}
            imageClassName="!size-[100px] object-cover"
          />
        </div>
      ),
    },
  ];

  const data2 = [
    {
      key: '1',
      title: 'Mã người dùng',
      value: (
        <span
          className="cursor-pointer text-blue-600"
          onClick={() =>
            setOpenDetailModel({ open: true, value: dataDetail?.user?.id })
          }
        >
          {dataDetail?.user?.code}
        </span>
      ),
    },
    {
      key: '2',
      title: 'Họ và tên',
      value: dataDetail?.user?.full_name,
    },
    {
      key: '3',
      title: 'Ngày sinh',
      value:
        dataDetail?.user?.birthday &&
        moment(dataDetail?.user?.birthday).format(DATE_FORMAT),
    },
    {
      key: '6',
      title: 'Số điện thoại',
      value: dataDetail?.user?.phone,
    },
  ];

  const data3 = [
    {
      key: '7',
      title: 'Giới tính',
      value: GENDER_TYPE[dataDetail?.user?.gender],
    },
    {
      key: '8',
      title: 'Email liên hệ',
      value: dataDetail?.user?.email,
    },
    {
      key: '4',
      title: 'Địa chỉ',
      value: dataDetail?.user?.address,
    },
    {
      key: '5',
      title: 'Trạng thái',
      value: (
        <Tag color={LECTURER_APPLICATION_STATUS_COLOR[dataDetail?.status]}>
          {LECTURER_APPLICATION_STATUS[dataDetail?.status]}
        </Tag>
      ),
    },
  ];

  const data4 = [
    {
      key: '9',
      title: 'Bằng cấp',
      value: (
        <div className="flex">
          <div className="line-clamp-1 flex-1">dsdsdsdsdsds,dsdsds</div>
          {/* <span
            className="text-blue-500 cursor-pointer"
            onClick={() => setOpenDetail(true)}
          >
            +{arr?.length}
          </span> */}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="divide-y-[1px] divide-dashed">
        <Row className=" gap-3 pb-4">
          <Col className="col-span-2 gap-2.5">
            <CardContent title="Thông tin chung">
              <Row className="grid-cols-2 xmd:grid-cols-3 gap-3">
                <Col className="space-y-2">{renderDescription(data1)}</Col>
                <Col className="space-y-2 flex flex-col justify-center">
                  {renderDescription(data2)}
                </Col>
                <Col className="space-y-2">{renderDescription(data3)}</Col>
              </Row>
            </CardContent>
          </Col>
        </Row>
        <Row className="py-4">
          <CardContent title="Thông tin giảng viên">
            <div
              className={
                'w-full max-h-[600px] overflow-y-auto mx-auto my-0 tailwind-preflight'
              }
            >
              <div
                dangerouslySetInnerHTML={{ __html: dataDetail?.information }}
              />
            </div>
          </CardContent>
        </Row>
        <Row className="py-4">
          <CardContent title="Tài liệu đính kèm">
            <div className="w-[70%] xmd:w-[40%]">
              {renderDescription(data4)}
            </div>
            <div className="mt-3">
              <UploadFiles
                fileList={files}
                wrapperClassName={'w-full'}
                isCount={false}
                onReceiveFiles={(_, files) => setFiles(files)}
                mode="view"
                onRemove={(fileDelete) => {
                  const newList = files.filter(
                    (file) => file.id !== fileDelete?.id,
                  );
                  setFiles(newList);
                }}
              />
            </div>
          </CardContent>
        </Row>
      </div>
      {openDetailModel.open && (
        <UserDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
    </>
  );
};

export default Overview;
