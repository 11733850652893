import Filter from '_common/component/Filter';
import {
  DATE_BACKEND_FORMAT,
  MAXIMUM_QUANTITY,
} from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseCategory from '_common/dof/Select/SelectCourseCategory';
import _ from 'lodash';
import { COURSE_TYPE } from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { COURSE_ACTIVITY_TYPE } from '../../constants';
import moment from 'moment';
import RangePicker from '_common/dof/Control/RangePicker';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function CourseCategoryFilter({
  open,
  initialValue,
  onClose,
  onFilter,
}: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      ...(values?.date?.[0] && {
        date: [
          values?.date?.[0]?.format(DATE_BACKEND_FORMAT),
          values?.date?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      type: null,
      category_id: null,
      from_number: null,
      to_number: null,
      date: null,
      activity_type: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;

    const data = _.pick(initialValue, [
      'type',
      'category_id',
      'from_number',
      'to_number',
      'activity_type',
    ]);
    const date = initialValue?.date?.split(',');
    form.reset({
      ...data,
      ...(date?.[0] && {
        date: [moment(date?.[0]), moment(date?.[1])],
      }),
    });
  }, [initialValue]);

  const typeOptions = Object.entries(COURSE_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const activityTypeOptions = Object.entries(COURSE_ACTIVITY_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Danh mục khóa học" name="category_id">
          <SelectCourseCategory allowClear />
        </FormTeraItem>
        <FormTeraItem label="Loại khóa học" name="type">
          <Select options={typeOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem name="" label="Học viên đã đăng ký">
          <RangeNumber
            startFormName="from_number"
            endFormName="to_number"
            endInputProps={{
              max: MAXIMUM_QUANTITY,
              min: 0,
              placeholder: 'Đến',
            }}
            startInputProps={{
              max: MAXIMUM_QUANTITY,
              min: 0,
              placeholder: 'Từ',
            }}
          />
        </FormTeraItem>
        <FormTeraItem label="Thời gian yêu cầu" name="date">
          <RangePicker
            className="w-full"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </FormTeraItem>
        <FormTeraItem label="Loại đơn yêu cầu" name="activity_type">
          <Select options={activityTypeOptions} />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default CourseCategoryFilter;
