export const PROMOTION_STATUS = {
  0: 'Ngừng hoạt động',
  1: 'Đang hoạt động',
};

export const PROMOTION_STATUS_COLOR = {
  1: 'green03',
  0: 'gray02',
};

export const PROMOTION_TYPE = {
  apply_first_time: 'Áp dụng lần đầu',
  apply_forever: 'Áp dụng mãi mãi',
};
