import Filter from '_common/component/Filter';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface PurchaseReturnFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

// const optionsAll: OptionProps[] = [
//   {
//     label: 'Tất cả',
//     value: null,
//   },
// ];

function PurchaseReturnFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: PurchaseReturnFilterProps) {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
      start_date: values?.start_date
        ? moment(values?.start_date).format('DD/MM/YYYY')
        : null,
      end_date: values?.end_date
        ? moment(values?.end_date).format('DD/MM/YYYY')
        : null,
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      start_date: null,
      end_date: null,
      time: null,
    };
    onFilter(values);
    onClose();
  };

  const handleChangeTime = (value) => {
    let start_date = moment().startOf('month');
    let end_date = moment().endOf('month');
    switch (value) {
      case 'today':
        start_date = moment();
        end_date = null;
        break;
      case 'week':
        start_date = moment().startOf('isoWeek');
        end_date = moment().endOf('isoWeek');
        break;
      case 'month':
        start_date = moment().startOf('month');
        end_date = moment().endOf('month');
        break;
      default:
        break;
    }

    newForm?.setValue('start_date', start_date);
    newForm?.setValue('end_date', end_date);
  };

  const handleChangeDate = (value) => {
    if (value) {
      newForm?.setValue('time', null);
    }
  };
  handleChangeTime;
  useEffect(() => {
    const values = _.pick(initialValue, ['start_date', 'end_date', 'time']);

    newForm?.reset({
      time: values?.time || null,
      start_date: values?.start_date
        ? moment(values?.start_date, 'DD/MM/YYYY')
        : null,
      end_date: values?.end_date
        ? moment(values?.end_date, 'DD/MM/YYYY')
        : null,
    });
  }, [initialValue]);

  // const optionsTime = Object.entries(FilterTime).map(([key, value]) => ({
  //   label: value,
  //   value: key,
  // }));

  const watchStartDate = newForm?.watch('start_date');
  const watchEndDate = newForm?.watch('end_date');

  useEffect(() => {
    if (watchStartDate && watchEndDate) {
      newForm?.trigger('end_date');
      newForm?.trigger('start_date');
    }
  }, [watchStartDate, watchEndDate, newForm]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem
          label="Từ ngày"
          name="start_date"
          rules={[
            {
              maxDate: {
                key: 'end_date',
                message: messageValidate.from_date,
              },
            },
          ]}
        >
          <DatePicker
            placeholder="Vui lòng chọn"
            onChange={handleChangeDate}
            format="DD/MM/YYYY"
          />
        </FormTeraItem>
        <FormTeraItem
          label="Đến ngày"
          name="end_date"
          rules={[
            {
              minDate: {
                key: 'start_date',
                message: messageValidate.to_date,
              },
            },
          ]}
        >
          <DatePicker
            placeholder="Vui lòng chọn"
            onChange={handleChangeDate}
            format="DD/MM/YYYY"
          />
        </FormTeraItem>
        {/* <FormTeraItem label="Thời gian" name="time">
          <Select
            placeholder="Vui lòng chọn"
            options={optionsTime}
            onSelect={handleChangeTime}
          />
        </FormTeraItem> */}

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default PurchaseReturnFilter;
