import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourse from '_common/dof/Select/SelectCourse';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import CourseApi from 'pages/CourseManagement/Course/api';
import { COURSE_METHOD } from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormItem,
  Input,
  InputNumber as InputNumberTera,
  Modal,
  notification,
  Spin,
} from 'tera-dls';
import PromotionSuggestionApi from '../../api';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  course_id?: string | number;
}

const PromotionSuggestionForm = (props: IProps) => {
  const { open, value: id, onClose, course_id: initialCourseId } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const course_id = form.watch('course_id');

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-promotion-suggestion-detail', id],
    () => PromotionSuggestionApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  const { data: detailCourse, refetch } = useQuery(
    ['get-course-detail', course_id, initialCourseId],
    () => CourseApi.getDetail(course_id ?? initialCourseId),
    {
      enabled: !!course_id || !!initialCourseId,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    initialCourseId && refetch();
  }, [initialCourseId]);

  useEffect(() => {
    if (!!detailCourse && !!initialCourseId) {
      form.setValue('type', detailCourse?.classification);
      form.setValue('course_id', initialCourseId);
    }
  }, [detailCourse, initialCourseId]);

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, ['course_id', 'price']);

      form.reset(data);
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id
        ? PromotionSuggestionApi.update(variables)
        : PromotionSuggestionApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-promotion-suggestion-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
      },
      ...(id && { id }),
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa đề xuất khuyến mãi' : 'Thêm đề xuất khuyến mãi'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem
              label="Khóa học"
              name="course_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectCourse
                paramsApi={{
                  include_id: course_id,
                }}
                disabled={!!initialCourseId}
              />
            </FormTeraItem>
            <FormItem label="Giảng viên" isRequired={false}>
              <Input disabled value={detailCourse?.lecturer?.full_name ?? ''} />
            </FormItem>
            <FormItem label="Phân loại khóa học" isRequired={false}>
              <Input
                disabled
                value={COURSE_METHOD[detailCourse?.classification] ?? ''}
              />
            </FormItem>

            <FormItem label="Số tiền gốc" isRequired={false}>
              <InputNumberTera
                disabled
                value={detailCourse?.price}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </FormItem>
            <FormTeraItem
              label="Số tiền khuyến mãi"
              name="price"
              rules={[
                {
                  required: messageValidate.emptyText,
                  max: {
                    value: detailCourse?.price || MAXIMUM_CURRENCY,
                    message: 'Không được vượt quá số tiền gốc',
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                max={detailCourse?.price || MAXIMUM_CURRENCY}
              />
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default PromotionSuggestionForm;
