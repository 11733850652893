function Menu17(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.57414 10.2628L11.7545 14.4432C12.497 15.1856 13.7007 15.1856 14.4432 14.4432C15.1856 13.7007 15.1856 12.497 14.4432 11.7545L10.2297 7.54111M7.57414 10.2628L9.36379 8.08964C9.59036 7.81451 9.8934 7.64142 10.2297 7.54111M7.57414 10.2628L4.23663 14.3155C3.88965 14.7368 3.37243 14.9809 2.82661 14.9809C1.8178 14.9809 1 14.1631 1 13.1543C1 12.6085 1.24409 12.0912 1.66542 11.7443L6.56699 7.70768M10.2297 7.54111C10.6236 7.42363 11.0632 7.40597 11.4791 7.44113C11.5699 7.4488 11.6618 7.45272 11.7545 7.45272C13.5364 7.45272 14.9809 6.00823 14.9809 4.22636C14.9809 3.75297 14.8789 3.30339 14.6958 2.89838L12.3469 5.2473C11.547 5.06367 10.9173 4.43402 10.7337 3.63412L13.0826 1.28516C12.6776 1.10198 12.228 1 11.7545 1C9.97266 1 8.52817 2.44449 8.52817 4.22636C8.52817 4.31912 8.53209 4.41097 8.53976 4.50175C8.60494 5.27282 8.48859 6.12519 7.89126 6.61711L7.81807 6.67738M6.56699 7.70768L3.6234 4.76409H2.61318L1 2.07545L2.07545 1L4.76409 2.61318V3.6234L7.81807 6.67738M6.56699 7.70768L7.81807 6.67738M12.5611 12.5611L10.6791 10.6791M2.87648 13.0989H2.88185V13.1043H2.87648V13.0989Z"
        stroke="#6B7280"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Menu17;
