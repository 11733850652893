export const ORDER_STATUS = {
  pending: 'Chờ duyệt',
  approve: 'Đã duyệt',
  reject: 'Từ chối',
  complete: 'Hoàn thành',
};

export const ORDER_STATUS_COLOR = {
  pending: 'yellow03',
  approve: 'green03',
  reject: 'red03',
  complete: 'blue03',
};

export const discountType = {
  not_available: 'Không có chiết khấu',
  fixed: 'Cố định',
  by_percentage: 'Theo % hóa đơn',
};
