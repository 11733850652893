export const USER_TYPE = {
  lecturer: 'Giảng viên',
  student: 'Học viên',
};

export const GENDER_TYPE = {
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
};

export const USER_STATUS = {
  1: 'Đang hoạt động',
  0: 'Ngừng hoạt động',
};

export const USER_STATUS_COLOR = {
  1: 'green03',
  0: 'gray02',
};
