export const COURSE_ORDER_STATUS = {
  pending: 'Chờ duyệt',
  received: 'Đã duyệt',
  reject: 'Đã hủy',
};

export const COURSE_ORDER_STATUS_COLOR = {
  pending: 'yellow03',
  received: 'green03',
  reject: 'gray03',
};
