import BasicLayout from '_common/component/Layout/BasicLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotfound from '_common/component/PageNotfound';
import PageNotPermission from '_common/component/PageNotPermission';
import PageUnauthorized from '_common/component/PageUnauthorized';
import { IRouteProps } from '_common/interface/router';
import CheckAuthPage from 'pages/Auth/CheckAuth';
import Login from 'pages/Auth/Login';
import { CourseRouter } from 'pages/CourseManagement/Course/router';
import { CourseActivityRouter } from 'pages/CourseManagement/CourseActivity/router';
import { CourseCategoryRouter } from 'pages/CourseManagement/CourseCategory/router';
import { CourseOrderRouter } from 'pages/CourseManagement/CourseOrder/router';
import Dashboard from 'pages/Dashboard';
import { LecturerRouter } from 'pages/LecturerManagement/Lecturer/router';
import { LecturerApplicationRouter } from 'pages/LecturerManagement/LecturerApplication/router';
import { ProductCategoryRouter } from 'pages/SaleManagement/Category/router';
import { ProductRouter } from 'pages/SaleManagement/Product/router';
import { SaleOrderRouter } from 'pages/SaleManagement/SaleOrder/router';
import { StudentRouter } from 'pages/StudentManagement/Student/router';
import { PromotionRouter } from 'pages/System/Promotion/router';
import { VideoGuideRouter } from 'pages/System/VideoGuide/router';
import { UserRouter } from 'pages/UserManagement/User/router';

import { Navigate, Route, Routes } from 'react-router-dom';
import CheckAuth from 'routers/CheckAuth';
import MiddlewareRouter from 'routers/MiddlewareRouter';

// dashboard router
const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path, actions, action_type } = route;
    return (
      <Route
        key={key}
        path={path}
        element={
          <PageLayout
            page_key={key}
            actions={actions}
            action_type={action_type}
          >
            {component}
          </PageLayout>
        }
      />
    );
  });
};
renderRouter;

export const Routers = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="system">
          {renderRouter(PromotionRouter)}
          {renderRouter(VideoGuideRouter)}
        </Route>
        <Route path="user-management">{renderRouter(UserRouter)}</Route>
        <Route path="course-management">
          {renderRouter(CourseCategoryRouter)}
          {renderRouter(CourseRouter)}
          {/* {renderRouter(PromotionSuggestionRouter)} */}
          {renderRouter(CourseOrderRouter)}
          {renderRouter(CourseActivityRouter)}
        </Route>
        <Route path="lecturer-management">
          {renderRouter(LecturerRouter)}
          {renderRouter(LecturerApplicationRouter)}
        </Route>
        <Route path="student-management">{renderRouter(StudentRouter)}</Route>
        <Route path="sale-management">
          {renderRouter(ProductRouter)}
          {renderRouter(ProductCategoryRouter)}
          {renderRouter(SaleOrderRouter)}
        </Route>
      </Route>

      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
      </Route>

      <Route path="*" element={<PageNotfound />} />
      <Route path="/403" element={<PageNotPermission />} />
      <Route path="/401" element={<PageUnauthorized />} />
    </Routes>
  );
};
