import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  EllipsisHorizontalOutlined,
  formatCurrency,
  Image,
  notification,
  PencilSquareOutlined,
  PlayCircleOutlined,
  Row,
  Spin,
  Tag,
  XMarkOutlined,
} from 'tera-dls';

import CardFormV2 from '_common/component/CardForm/CardFormV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import { IFormModel } from 'pages/CourseManagement/Course';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import { useEffect, useState } from 'react';
import QuestionIcon from 'styles/images/Icons/QuestionIcon';
import customTwMerge from 'tailwind-merge.config';
import CourseActivityApi from '../../api';
import {
  COURSE_ACTIVITY_STATUS,
  COURSE_ACTIVITY_STATUS_COLOR,
  COURSE_ACTIVITY_TYPE,
} from '../../constants';
import { COURSE_ACTIVITY_URL } from '../../url';
import CourseActivityForm from '../Form';

const borderBox =
  'shadow-xsm border-[1px] border-blue-400 rounded-[6px] relative';
const iconBorder =
  'bg-blue-600 p-2 flex justify-center items-center w-10 h-10 rounded-full absolute top-[-16px] left-[16px]';

const CourseActivityDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(
    ['get-course-activity-detail', id],
    () => CourseActivityApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );
  const { course } = dataDetail ?? {};

  useEffect(() => {
    !!id && refetch();
  }, [id]);

  const { mutate: mutateChangeStatus } = useMutation(
    (variables: any) => {
      if (variables?.status === 'reject')
        return CourseActivityApi.reject({ id: variables?.id });
      return CourseActivityApi.approve({ id: variables?.id });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-activity-list']);
          queryClient.invalidateQueries(['get-course-activity-summary']);
          queryClient.invalidateQueries(['get-course-activity-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleChangeStatus = (status: 'approve' | 'reject', record): void => {
    const text = {
      approve: 'duyệt',
      reject: 'từ chối',
    };
    const activity = {
      open: 'mở',
      close: 'đóng',
    };

    confirm.warning({
      title: `Xác nhận ${text[status]} yêu cầu ${
        activity[record?.activity_type]
      } khóa học`,
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn {text[status]} yêu cầu</p>
          <p>{activity[record?.activity_type]} khóa học</p>
          <p>
            <b>{record?.course?.name}</b> này không
          </p>
        </p>
      ),
      onOk: () => {
        mutateChangeStatus({ status, id: record?.id });
      },
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseActivityApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-activity-list']);
          queryClient.invalidateQueries(['get-course-activity-summary']);
          notification.success({
            message: res?.msg,
          });
          navigate(COURSE_ACTIVITY_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận xóa hoạt động khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận xóa hoạt động khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItems = [
    ...(dataDetail?.status === 'pending'
      ? [
          {
            key: 4,
            label: <a>Duyệt</a>,
            onClick: () => handleChangeStatus('approve', dataDetail),
          },
          {
            key: 5,
            label: <a>Từ chối</a>,
            onClick: () => handleChangeStatus('reject', dataDetail),
          },
          {
            key: 6,
            label: 'Sửa',
            onClick: () =>
              setOpenFormModel({ open: true, value: dataDetail?.id }),
          },
          {
            key: 7,
            label: <a className="text-red-500">Xóa</a>,
            onClick: () => handleDelete(dataDetail?.id, dataDetail?.name),
          },
        ]
      : []),
  ];

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        {dataDetail?.status === 'pending' && (
          <>
            <ButtonCommon
              onClick={() => handleChangeStatus('approve', dataDetail)}
              className="page-header-btn"
            >
              Duyệt
            </ButtonCommon>
            <ButtonCommon
              onClick={() => handleChangeStatus('reject', dataDetail)}
              className="page-header-btn"
              type="danger"
            >
              Từ chối
            </ButtonCommon>
            <ButtonCommon
              icon={<PencilSquareOutlined className="w-4 h-4" />}
              onClick={() =>
                setOpenFormModel({ open: true, value: dataDetail?.id })
              }
              className="page-header-btn"
            >
              Sửa
            </ButtonCommon>
            <ButtonCommon
              icon={<XMarkOutlined className="w-4 h-4" />}
              onClick={() =>
                handleDelete(dataDetail?.id, dataDetail?.course?.name)
              }
              type="danger"
              className="page-header-btn"
            >
              Xóa
            </ButtonCommon>
          </>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  const data1 = [
    {
      key: '1',
      title: 'Tên khóa học',
      value: course?.name,
    },
    {
      key: '2',
      title: 'Danh mục khóa học',
      value: course?.category?.name,
    },
    {
      key: '4',
      title: 'Loại khoá học',
      value: COURSE_TYPE[course?.type],
    },

    {
      key: '5',
      title: 'Phân loại khoá học',
      value: (
        <span className={`${COURSE_METHOD_COLOR[course?.classification]}`}>
          {COURSE_METHOD[course?.classification]}
        </span>
      ),
    },
    {
      key: '6',
      title: 'Giá khoá học',
      value: formatCurrency(course?.price ?? 0),
    },
    {
      key: '7',
      title: 'Giá khuyến mãi',
      value: formatCurrency(course?.discount_price ?? 0),
    },
  ];

  const data2 = [
    {
      key: '1',
      title: 'Ngày yêu cầu',
      value: moment(dataDetail?.created_at).format(DATE_FORMAT),
    },
    {
      key: '2',
      title: 'Loại yêu cầu',
      value: COURSE_ACTIVITY_TYPE[dataDetail?.activity_type],
    },
    {
      key: '3',
      title: 'Giảng viên',
      value: (
        <HoverQuickView name={course?.lecturer?.full_name}>
          <span className="line-clamp-1 text-blue-600">
            {course?.lecturer?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      key: '9',
      title: 'Trạng thái ',
      value: (
        <Tag color={COURSE_ACTIVITY_STATUS_COLOR[dataDetail?.status]}>
          {COURSE_ACTIVITY_STATUS[dataDetail?.status]}
        </Tag>
      ),
    },
  ];
  const data3 = [
    {
      key: '1',
      title: 'Tổng thời gian của khóa học',
      value: course?.total_duration_formatted,
    },
    {
      key: '2',
      title: 'Số lượng video',
      value: 0,
    },
    {
      key: '3',
      title: 'Số lượng bài tập',
      value: 0,
    },
    {
      key: '4',
      title: 'Số lượng học viên',
      value: course?.students_count ?? 0,
    },

    {
      key: '5',
      title: 'Số lượng đánh giá hiện tại',
      value: course?.students_registered_count,
    },
    {
      key: '6',
      title: 'Doanh thu',
      value: formatCurrency(0),
    },
  ];
  return (
    <>
      <Spin spinning={isFetching}>
        <div className="tera-page-form !gap-0">
          <div className="page-header-sticky bg-[#F3F3F9]">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div
                  className="page-header__breadcrumb-back cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowSmallLeftSolid className="h-6 w-6" />
                </div>
                <Breadcrumb
                  separator={'/'}
                  containerItemClassName="text-sm"
                  items={[
                    {
                      title: (
                        <a className="text-blue-400 hover:text-blue-600">
                          Danh sách hoạt động khóa học
                        </a>
                      ),
                      onClick: () => navigate(COURSE_ACTIVITY_URL.list.path),
                    },
                    {
                      title: `Chi tiết hoạt động khóa học`,
                    },
                  ]}
                />
              </div>
              <div className="page-header-v2__function">{buttonAction}</div>
            </div>
          </div>
          <div className=" page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
            <Row className="grid-cols-6 pt-5 gap-[30px]">
              <Col className="col-span-1">
                <div
                  className={customTwMerge(
                    'col-span-1 flex flex-col p-[16px] divide-y-[1px]',
                    borderBox,
                  )}
                >
                  <div className="flex flex-col justify-center items-center gap-2.5 pb-2.5">
                    <Image
                      src={course?.image_url}
                      containerClassName="size-[150px] rounded-[10px] overflow-hidden"
                      imageClassName=" object-cover"
                      borderRadius={10}
                    />
                    <div className="space-y-[5px] text-center break-word">
                      <p className="text-[16px] leading-[20px] tracking-[1px]">
                        {course?.name}
                      </p>
                      <p className="text-[#0E9F6E]">{course?.code}</p>
                    </div>
                  </div>
                  <div className="pt-2.5 flex flex-col gap-2.5">
                    <div className="text-[#6B7280]">Người tạo</div>
                    <div className="text-blue-600">
                      {dataDetail?.user_created?.full_name}
                    </div>
                    <div className="text-[#6B7280]">Ngày tạo</div>
                    <div className="text-[#1F2937]">
                      {dataDetail?.created_at &&
                        moment(dataDetail?.created_at).format(DATE_TIME_FORMAT)}
                    </div>
                    {dataDetail?.user_updated && (
                      <>
                        <div className="text-[#6B7280]">Người cập nhật</div>
                        <div className="text-blue-600">
                          {dataDetail?.user_updated?.full_name}
                        </div>
                        <div className="text-[#6B7280]">Ngày cập nhật</div>
                        <div className="text-[#1F2937]">
                          {moment(dataDetail?.updated_at).format(
                            DATE_TIME_FORMAT,
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-span-5 flex flex-col gap-[30px]">
                <div
                  className={customTwMerge(
                    'flex flex-col bg-white p-5 gap-5',
                    borderBox,
                  )}
                >
                  <div className={customTwMerge('', iconBorder)}>
                    <QuestionIcon className="w-full h-full text-white" />
                  </div>
                  <CardFormV2 title="Thông tin chung">
                    <Row className="grid grid-cols-2">
                      <Col className="flex flex-col gap-2">
                        {renderDescription(data1)}
                      </Col>
                      <Col className="flex flex-col gap-2">
                        {renderDescription(data2)}
                      </Col>
                    </Row>
                  </CardFormV2>
                </div>
                <div
                  className={customTwMerge(
                    'flex flex-col bg-white p-5 gap-5',
                    borderBox,
                  )}
                >
                  <div className={customTwMerge('', iconBorder)}>
                    <PlayCircleOutlined className="w-full h-full text-white" />
                  </div>
                  <CardFormV2 title="Thông tin khóa học">
                    <Row className="grid grid-cols-1">
                      {renderDescription(
                        data3,
                        'xmd:!max-w-[200px] xmd:!min-w-[200px]',
                      )}
                    </Row>
                  </CardFormV2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      {openFormModel.open && (
        <CourseActivityForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
    </>
  );
};

export default CourseActivityDetail;
