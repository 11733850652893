import { useQuery } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import HoverQuickView from '_common/component/HoverQuickView';
import ImageBackground from '_common/component/ImageBackground';
import VideoBackground from '_common/component/VideoBackground';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import {
  COURSE_STATUS,
  COURSE_STATUS_COLOR,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import UserApi from 'pages/UserManagement/User/api';
import { useCallback, useEffect, useState } from 'react';
import {
  BookmarkOutlined,
  Button,
  Collapse,
  formatCurrency,
  Image,
  Modal,
  Spin,
  StarOutlined,
  Tag,
  TextArea,
} from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const StudentEvaluate = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [activeKeys, setActiveKeys] = useState<any>();

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const listCollapse = useCallback(
    () => [
      {
        key: 1,
        onClick: () => 1,
        label: (
          <div className="!text-gray-400 text-xs font-normal">
            Trả lời đánh giá
          </div>
        ),
        children: (
          <div className="flex flex-col gap-[5px]">
            <TextArea
              rows={8}
              className="bg-gray-100 border-none resize-none text-gray-700"
              placeholder="Hãy chia sẽ nhận xét cho học viên này bạn nhé!"
            />
            <div className="flex justify-end">
              <ButtonCommon
                htmlType="submit"
                type="success"
                className="page-header-btn bg-green-500 font-light"
                icon={<BookmarkOutlined className="w-4" />}
                onClick={() => 1}
              >
                Lưu
              </ButtonCommon>
            </div>
          </div>
        ),
      },
    ],
    [],
  );

  const data = [
    {
      key: 1,
      title: 'Ảnh đại diện',
      value: (
        <div className="flex justify-start mb-2.5">
          <ImageBackground
            isView
            value={{ url: detail?.avatar }}
            isShowBtnDelete={false}
            imageClassName="!size-[100px] object-cover"
          />
        </div>
      ),
    },
    {
      key: 2,
      title: 'Giảng viên',
      value: (
        <HoverQuickView name={detail?.user_created?.full_name}>
          <span className="line-clamp-1 text-blue-600">
            {detail?.user_created?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      key: 3,
      title: 'Tên khóa học',
      value: 'OK',
    },
    {
      key: 4,
      title: 'Loại khóa học',
      value: COURSE_TYPE['basic'],
    },
    {
      key: 5,
      title: 'Giá khóa học',
      value: formatCurrency(0),
    },
    {
      key: 6,
      title: 'Giá khuyến mãi',
      value: formatCurrency(0),
    },
    {
      key: 7,
      title: 'Thời gian khuyến mãi',
      value: (
        <span>
          {moment().format(DATE_FORMAT)} - {moment().format(DATE_FORMAT)}
        </span>
      ),
    },
    {
      key: 8,
      title: 'Trạng thái',
      value: (
        <Tag color={COURSE_STATUS_COLOR[1] as any}>{COURSE_STATUS[1]}</Tag>
      ),
    },
  ];
  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết đánh giá khóa học'}
        closeIcon={false}
        width={600}
        onCancel={onClose}
        footer={
          <Button onClick={onClose} className="px-5">
            Đóng
          </Button>
        }
      >
        <Spin spinning={isLoading}>
          <div className="space-y-2 mb-2">{renderDescription(data)}</div>
          <div className="rounded-[10px] border border-blue-500 p-2.5">
            <div className="space-y-2.5">
              <div className="flex gap-2.5 items-center">
                <Image
                  src={''}
                  containerClassName="!size-[40px] rounded-full overflow-hidden"
                  imageClassName="object-cover"
                />
                <div className="space-y-2">
                  <span className="line-clamp-1 text-blue-600 font-bold">
                    Nguyễn Anh thơ
                  </span>
                  <div className="flex gap-2.5 items-center">
                    <div className="flex text-[#E3A008]">
                      <StarOutlined className="size-4 fill-[#E3A008]" />
                      <StarOutlined className="size-4 fill-[#E3A008]" />
                      <StarOutlined className="size-4 fill-[#E3A008]" />
                      <StarOutlined className="size-4 fill-[#E3A008]" />
                      <StarOutlined className="size-4 fill-[#E3A008]" />
                    </div>
                    <span className="size-[3px] bg-gray-700 rounded-full" />
                    <div className="leading-[17px] text-[#374151]">
                      {moment().format(DATE_TIME_FORMAT)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2.5">
                <p className="break-word text-xs leading-[20px] text-gray-700">
                  Cô dạy tận tâm, dễ hiểu, khoá học rất vui Cô dạy tận tâm, dễ
                  hiểu, khoá học rất vui Cô dạy tận tâm, dễ hiểu, khoá học rất
                  vui Cô dạy tận tâm, dễ hiểu, khoá học rất vui Cô dạy tận tâm,
                  dễ hiểu, khoá học rất vui Cô dạy tận tâm, dễ hiểu, khoá học
                  rất vui Cô dạy tận tâm, dễ hiểu, khoá học rất vui Cô dạy tận
                  tâm, dễ hiểu, khoá học rất vui Cô dạy tận tâm, dễ hiểu, khoá
                  học rất vui
                </p>
                <div className="flex gap-2.5 items-center">
                  <VideoBackground
                    value={{
                      url: 'https://bake-api.teravn.com/assets/upload/lecture-item/1726456051_download-2.mp4',
                    }}
                    isView
                    type={'uploaded_video'}
                  />
                  <VideoBackground
                    value={{
                      url: 'https://youtu.be/Bhg-Gw953b0?si=TGZqwhlF8O74Sj3q',
                    }}
                    isView
                    type={'youtube_link'}
                  />
                  <VideoBackground
                    value={{
                      url: 'https://drive.google.com/file/d/1ZgHHHyQeSGqjDKtbDQ4m8dzewByRr3b8/view?usp=drive_link',
                    }}
                    isView
                    type={'gg_drive_link'}
                  />
                </div>
              </div>
              <div>
                <Collapse
                  activeKey={activeKeys}
                  containerClassName="flex flex-col gap-1 w-full"
                  headingClassName={
                    'p-1 bg-transparent !rounded-e-full focus:ring-0 flex flex-row-reverse gap-2.5 border-none border-b-[1px] border-gray-500'
                  }
                  contentClassName={'border-none p-0 !text-red-500'}
                  onChange={(key: any) => setActiveKeys(key)}
                  items={listCollapse()}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default StudentEvaluate;
