import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { usePrompt } from '_common/hooks/usePrompt';
import _ from 'lodash';
import moment from 'moment';
import { CardContent } from 'pages/SaleManagement/Product/containers/Form';
import { GENDER_TYPE } from 'pages/UserManagement/User/constants';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  notification,
  Row,
  Spin,
} from 'tera-dls';
import { default as ProductApi, default as StudentApi } from '../../api';
import { STUDENT_URL } from '../../url';

const StudentForm = () => {
  const navigate = useNavigate();
  const form = useForm({ mode: 'onChange' });

  const {
    formState: { isDirty },
  } = form;
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<any>();

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(['get-student-detail', id], () => StudentApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, [
        'full_name',
        'address',
        'phone',
        'email',
        'type',
        'gender',
      ]);
      form.reset({
        ...data,
        is_active: Boolean(detail?.is_active),
        ...(detail.birthday && { birthday: moment(detail.birthday) }),
      });
      setImage({ url: detail?.avatar });
    }
  }, [detail]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id ? ProductApi.update(variables) : ProductApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-student-list']);
          queryClient.invalidateQueries(['get-student-detail']);
          notification.success({
            message: res?.msg,
          });
          form.reset();
          setTimeout(() => {
            navigate(-1);
          }, 200);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        ...(values?.birthday && {
          birthday: values?.birthday.format(DATE_BACKEND_FORMAT),
        }),
        avatar: image?.url ?? null,
      },
      ...(id && { id }),
    });
  };

  const genderOptions = Object.entries(GENDER_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div className="tera-page-form !gap-0">
      <div className="page-header-sticky bg-[#FAFAF9]">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              containerItemClassName="text-sm"
              items={[
                {
                  title: (
                    <a className="cursor-pointer text-blue-400 italic ">
                      Danh sách học viên
                    </a>
                  ),
                  onClick: () => navigate(STUDENT_URL.list.path),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px]">
                      {id ? 'Sửa học viên' : 'Thêm học viên'}
                    </a>
                  ),
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function">
            <ButtonCommon
              htmlType="submit"
              type="success"
              className="page-header-btn bg-green-500"
              icon={<BookmarkOutlined className="w-4" />}
              onClick={form.handleSubmit(handleSubmitForm)}
              loading={isLoading}
              disabled={isLoading}
            >
              Lưu
            </ButtonCommon>
          </div>
        </div>
      </div>
      <div className="page-content-v2 bg-white shadow-xsm !rounded-xl px-2.5 h-full mx-2.5 mb-2.5 !gap-y-0">
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <div className="divide-y-[1px] divide-dashed">
              <Row className=" gap-5 pb-5">
                <CardContent title="Tài liệu đính kèm">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-1 space-y-6">
                      <FormTeraItem label="Ảnh đại diện" name="">
                        <ImageBackground
                          value={image}
                          onChange={(val) => {
                            setImage(val);
                          }}
                          isShowBtnDelete
                          imageClassName="!size-[100px] object-cover"
                          object_key={'course-category'}
                          folder={'bake-teach'}
                        />
                      </FormTeraItem>
                      <FormTeraItem
                        label="Họ và tên"
                        name="full_name"
                        rules={[
                          {
                            required: messageValidate.emptyText,
                          },
                        ]}
                      >
                        <Input maxLength={255} />
                      </FormTeraItem>
                    </div>
                    <div className="col-span-1 space-y-3">
                      <FormTeraItem label="Số điện thoại" name="phone">
                        <Input disabled={!!id} />
                      </FormTeraItem>
                      <FormTeraItem label="Giới tính" name="gender">
                        <Select
                          allowClear={false}
                          placeholder="Vui lòng chọn"
                          options={genderOptions}
                        />
                      </FormTeraItem>
                      <FormTeraItem label="Ngày sinh" name="birthday">
                        <DatePicker placeholder={'Vui lòng chọn'} />
                      </FormTeraItem>
                    </div>
                    <div className="col-span-1 space-y-3">
                      <FormTeraItem label="Địa chỉ" name="address">
                        <Input maxLength={255} />
                      </FormTeraItem>

                      <FormTeraItem label="Email liên hệ" name="email">
                        <Input maxLength={320} />
                      </FormTeraItem>
                    </div>
                  </div>
                </CardContent>
              </Row>
            </div>
          </FormTera>
        </Spin>
      </div>
    </div>
  );
};

export default StudentForm;
