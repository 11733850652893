import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Dropdown,
  EllipsisHorizontalOutlined,
  notification,
  Spin,
  XMarkOutlined,
} from 'tera-dls';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import LecturerApplicationApi from '../../api';
import { LECTURER_APPLICATION_URL } from '../../url';
import Overview from './Overview';

const LecturerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(
    ['get-lecturer-application-detail', id],
    () => LecturerApplicationApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    !!id && refetch();
  }, [id]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => LecturerApplicationApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-lecturer-application-list']);
          queryClient.invalidateQueries(['get-lecturer-application-summary']);
          notification.success({
            message: res?.msg,
          });
          navigate(LECTURER_APPLICATION_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa đăng ký giảng viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa đăng ký giảng viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItems = [
    ...(dataDetail?.status == 'reject'
      ? [
          {
            key: '3',
            label: 'Xóa',
            onClick: () => handleDelete(dataDetail?.id, dataDetail?.name),
          },
        ]
      : []),
  ];

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        {dataDetail?.status == 'reject' && (
          <ButtonCommon
            icon={<XMarkOutlined className="w-4 h-4" />}
            onClick={() => handleDelete(dataDetail?.id, dataDetail?.name)}
            type="danger"
            className="page-header-btn"
          >
            Xóa
          </ButtonCommon>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  return (
    <Spin spinning={isFetching}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="text-blue-400 hover:text-blue-600">
                        Danh sách đăng ký giảng viên
                      </a>
                    ),
                    onClick: () => navigate(LECTURER_APPLICATION_URL.list.path),
                  },
                  {
                    title: 'Chi tiết đăng ký giảng viên',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">{buttonAction}</div>
          </div>
        </div>
        <div className=" page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Overview dataDetail={dataDetail} />
        </div>
      </div>
    </Spin>
  );
};

export default LecturerDetail;
