import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  EllipsisHorizontalOutlined,
  getQueryParams,
  notification,
  PencilSquareOutlined,
  Spin,
  Tabs,
  updateQueryParams,
  XMarkOutlined,
} from 'tera-dls';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { removeNullObject } from '_common/utils';
import { useEffect, useMemo } from 'react';
import LecturerApi from '../../api';
import { LECTURER_URL } from '../../url';
import OnlineTab from './OnlineTab';
import Overview from './Overview';

const LecturerDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(['get-lecturer-detail', id], () => LecturerApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  const paramsObject = queryParams?.lecture
    ? JSON.parse(queryParams?.lecture)
    : {};

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-lecturer-summary-detail', id, paramsObject],
    () =>
      LecturerApi.getSummaryDetail({
        lecturer_id: id,
        ...removeNullObject(paramsObject),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (!!id) {
      refetchSummary();
      refetch();
    }
  }, [id]);

  const tabItems = useMemo(() => {
    return [
      {
        key: 'info',
        label: <span>Thông tin chung</span>,
      },
      {
        key: 'offline',
        label: (
          <h3 className="flex gap-2.5">
            <span>Danh sách khóa học offline</span>
            <Badge showZero count={summary?.count_offline ?? 0} />
          </h3>
        ),
      },
      {
        key: 'online',
        label: (
          <h3 className="flex gap-2.5">
            <span>Danh sách khóa học online</span>
            <Badge showZero count={summary?.count_online ?? 0} />
          </h3>
        ),
      },
    ];
  }, [summary]);

  const handleChangeTabs = (currentTab: string) => {
    handleUpdateQueryParams({ tab: currentTab });
  };

  const nodeContent = {
    info: <Overview dataDetail={dataDetail} />,
    online: <OnlineTab classification="online" lecturerId={dataDetail?.id} />,
    offline: <OnlineTab classification="offline" lecturerId={dataDetail?.id} />,
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => LecturerApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-lecturer-list']);
          queryClient.invalidateQueries(['get-lecturer-summary']);
          notification.success({
            message: res?.msg,
          });
          navigate(LECTURER_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa giảng viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa giảng viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItems = [
    ...(dataDetail?.is_active === 0
      ? [
          {
            key: '3',
            label: 'Xóa',
            onClick: () => handleDelete(dataDetail?.id, dataDetail?.name),
          },
        ]
      : []),
    {
      key: '4',
      label: 'Sửa',
      onClick: () => navigate(`${LECTURER_URL.update.path}/${dataDetail?.id}`),
    },
  ];

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        <ButtonCommon
          icon={<PencilSquareOutlined className="w-4 h-4" />}
          onClick={() =>
            navigate(`${LECTURER_URL.update.path}/${dataDetail?.id}`)
          }
          className="page-header-btn"
        >
          Sửa
        </ButtonCommon>
        {dataDetail?.is_active === 0 && (
          <ButtonCommon
            icon={<XMarkOutlined className="w-4 h-4" />}
            onClick={() => handleDelete(dataDetail?.id, dataDetail?.name)}
            type="danger"
            className="page-header-btn"
          >
            Xóa
          </ButtonCommon>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  return (
    <Spin spinning={isFetching}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="text-blue-400 hover:text-blue-600">
                        Danh sách giảng viên
                      </a>
                    ),
                    onClick: () => navigate(LECTURER_URL.list.path),
                  },
                  {
                    title: 'Chi tiết giảng viên',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">{buttonAction}</div>
          </div>
        </div>
        <div className=" page-content-v2  bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Tabs
            onChange={handleChangeTabs}
            items={tabItems}
            activeKey={queryParams?.tab ?? 'info'}
          />
          <div>{nodeContent[queryParams?.tab ?? 'info']}</div>
        </div>
      </div>
    </Spin>
  );
};

export default LecturerDetail;
