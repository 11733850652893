import { useState } from 'react';
import imageError from 'styles/images/video-clip-deny.png';
import ModalPlayVideo from './ModalPlayVideo';
import customTwMerge from 'tailwind-merge.config';
import { Spin } from 'tera-dls';

const getId = (url) => {
  const text = url?.substring(url?.indexOf('file/d/') + 'file/d/'.length);
  const id = text?.substring(0, text.indexOf('/'));
  return id;
};

const getThumbnail = (url) => {
  const id = getId(url);
  return `https://drive.google.com/thumbnail?id=${id}`;
};

const GoogleDriveVideo = (props) => {
  const { url, isView, isReview = true, size = 'normal', ...restProps } = props;
  const [openReview, setOpenReview] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const newUrl = url?.substring(0, url?.lastIndexOf('/'));
  const [errorThumbnail, setErrorThumbnail] = useState<boolean>(false);
  const isSmallSize = size === 'small';

  if (isView) {
    return (
      <>
        <div
          className={customTwMerge(
            'cursor-pointer relative size-[82px]',
            isSmallSize && '!size-[40px]',
            (!isReview || errorThumbnail) &&
              'cursor-default pointer-events-none',
          )}
          onClick={() => isReview && !errorThumbnail && setOpenReview(true)}
        >
          {isReview && !errorThumbnail && (
            <div className="absolute flex justify-center items-center size-full">
              <div
                style={{ clipPath: 'polygon(0 0, 0% 100%, 98% 52%)' }}
                className={customTwMerge(
                  'size-[28px] bg-white drop-shadow-sm',
                  isSmallSize && 'size-[12px]',
                )}
              />
            </div>
          )}
          <img
            className=" w-full h-full object-cover"
            {...restProps}
            src={errorThumbnail ? imageError : getThumbnail(url)}
            onError={() => setErrorThumbnail(true)}
          />
        </div>
        {openReview && (
          <ModalPlayVideo
            open={openReview}
            onClose={() => setOpenReview(false)}
            url={`${newUrl}/preview`}
            type={'gg_drive_link'}
          />
        )}
      </>
    );
  }

  return (
    <>
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-[#5b5a5a55] z-10 ">
          <Spin
            spinning
            wrapperClassName="size-full flex justify-center items-center"
          />
        </div>
      )}
      <object
        width={1000}
        height={700}
        {...restProps}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        data={`${newUrl}/preview`}
      />
    </>
  );
};

export default GoogleDriveVideo;
