import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  formatCurrency,
  getQueryParams,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import { default as PromotionApi, default as UserApi } from './api';
import {
  PROMOTION_STATUS,
  PROMOTION_STATUS_COLOR,
  PROMOTION_TYPE,
} from './constants';
import UserDetail from './containers/Detail';
import UserFilter from './containers/Filter';
import PromotionForm from './containers/Form';
import Searching from './containers/Searching';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Promotion = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const params = _.omit(queryParams, ['status']);
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-promotion-list', queryParams],
    () =>
      UserApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-promotion-summary', queryParams],
    () =>
      PromotionApi.getSummaryList({
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(PROMOTION_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{PROMOTION_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const { mutate: mutateStop } = useMutation(
    (variables: any) => PromotionApi.stop(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-promotion-list']);
          queryClient.invalidateQueries(['get-promotion-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => PromotionApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-promotion-list']);
          queryClient.invalidateQueries(['get-promotion-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleStatus = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận ngưng mã ưu đãi',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận ngưng mã ưu đãi</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateStop({ id });
      },
    });
  };

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận xóa mã ưu đãi',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa mã ưu đãi</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a onClick={() => record}>Xem</a>,
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },

      {
        key: 6,
        label: <a>Sửa</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
      ...(!Boolean(record?.status)
        ? [
            {
              key: 7,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.code),
            },
          ]
        : [
            {
              key: 5,
              label: <a>Ngưng hoạt động</a>,
              onClick: () => handleStatus(record?.id, record?.code),
            },
          ]),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Giảm giá theo tiền',
      dataIndex: 'discount_by_money',
      width: 150,
      render: (val) => val && formatCurrency(val),
    },
    {
      title: 'Giảm giá theo %',
      dataIndex: 'discount_by_percentage',
      width: 150,
      render: (val) => val && `${val ?? 0}%`,
    },
    {
      title: 'Đã sử dụng',
      dataIndex: 'used_count',
      width: 100,
    },
    {
      title: 'Kiểu giảm giá',
      dataIndex: 'type',
      width: 150,
      render: (val) => PROMOTION_TYPE[val],
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'date_expire',
      width: 100,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Trạng thái sử dụng',
      width: 150,
      dataIndex: 'status',
      render: (val) => (
        <Tag color={PROMOTION_STATUS_COLOR[val]}>{PROMOTION_STATUS[val]}</Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH ƯU ĐÃI VÀ KHUYẾN MÃI"
          onClickButtonAdd={() => setOpenFormModel({ open: true })}
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              loading={isFetching}
              wrapperClassName="shadow-none"
              rowKey={'id'}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>
      {openFormModel.open && (
        <PromotionForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
      {openDetailModel.open && (
        <UserDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openFilter && (
        <UserFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default Promotion;
