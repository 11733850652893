import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import CourseCategoryApi from './api';
import CourseCategoryDetail from './containers/Detail';
import CourseCategoryFilter from './containers/Filter';
import CourseCategoryForm from './containers/Form';
import Searching from './containers/Searching';

interface IFormModel {
  open: boolean;
  value?: string | number;
}

const CourseCategory = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const queryClient = useQueryClient();

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-category-list', queryParams],
    () =>
      CourseCategoryApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseCategoryApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-category-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa danh mục khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa danh mục khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a onClick={() => record}>Xem</a>,
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },
      {
        key: 2,
        label: <a>Sửa</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
    ];
    dropdownItems.push({
      key: 3,
      label: <a className="text-red-500">Xóa</a>,
      onClick: () => handleDelete(record?.id, record?.name),
    });

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã danh mục',
      dataIndex: 'code',
      width: 150,
      render: (val) => (
        <div className="flex gap-2 items-center line-clamp-1">
          <span className="text-blue-600">{val}</span>
        </div>
      ),
    },
    {
      title: 'Tên danh mục khóa mục',
      dataIndex: 'name',
      width: 200,
      render: (val, record) => (
        <span
          className="line-clamp-2  text-blue-600 cursor-pointer inline"
          onClick={() => setOpenDetailModel({ open: true, value: record?.id })}
        >
          {val}
        </span>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'user_created',
      width: 150,
      render: (value) => (
        <HoverQuickView
          avatarUrl={value?.avatar}
          // email={submitter?.email}
          // phone={submitter?.phone}
          // code={submitter?.code}
          name={value?.full_name}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH DANH MỤC KHÓA HỌC"
          onClickButtonAdd={() => setOpenFormModel({ open: true })}
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              wrapperClassName="shadow-none"
              rowKey={'id'}
              loading={isFetching}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>
      {openFormModel.open && (
        <CourseCategoryForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
      {openDetailModel.open && (
        <CourseCategoryDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openFilter && (
        <CourseCategoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default CourseCategory;
