import Menu13 from '_common/component/Icons/Menu13';
import Menu17 from '_common/component/Icons/Menu17';
import Menu18 from '_common/component/Icons/Menu18';
import AffiliateIcon from 'styles/images/Icons/AffiliateIcon';
import {
  ArrowUturnLeftSolid,
  ChartPieOutlined,
  DocumentChartBarOutlined,
  HomeOutlined,
  LockClosedOutlined,
  PlayCircleOutlined,
  PlayCircleSolid,
  ReceiptPercentOutlined,
  StopCircleOutlined,
  TruckOutlined,
  UserGroupOutlined,
  UserOutlined,
  UsersOutlined,
  VideoCameraOutlined,
} from 'tera-dls';

const menu = {
  groupMenu: [
    {
      id: 'r1',
      code: 'dashboard',
      title: 'Trang chủ',
      path: '/dashboard',
      iconNode: <HomeOutlined />,
      key: 'dashboard',
    },
    {
      id: 'r2',
      code: 'course-management',
      title: 'Khóa học',
      path: 'course-management/course/list',
      iconNode: <PlayCircleSolid />,
      key: 'course-management',
    },
    {
      id: 'r3',
      code: 'user-management',
      title: 'Người dùng',
      path: '/user-management/user/list',
      iconNode: <UsersOutlined />,
      key: 'user-management',
    },
    {
      id: 'r4',
      code: 'lecturer-management',
      title: 'Giảng viên',
      path: '/lecturer-management/lecturer/list',
      iconNode: <UserOutlined />,
      key: 'lecturer-management',
    },
    {
      id: 'r5',
      code: 'student-management',
      title: 'Học viên',
      path: '/student-management/student/list',
      iconNode: <UserGroupOutlined />,
      key: 'student-management',
    },
    {
      id: 'r6',
      code: 'report',
      title: 'Báo cáo',
      path: '/report',
      iconNode: <ChartPieOutlined className="text-gray-700" />,
      key: 'report',
    },
    {
      id: 'r7',
      code: 'affiliates',
      title: 'Affiliates',
      path: '/affiliates',
      iconNode: <AffiliateIcon />,
      key: 'affiliates',
    },
    {
      id: 'r8',
      code: 'sale-management',
      title: 'Bán hàng',
      path: 'sale-management/product/list',
      iconNode: <TruckOutlined className="text-gray-500" />,
      key: 'sale-management',
    },
    {
      id: 'r9',
      code: 'finance',
      title: 'Tài chính',
      path: '/finance',
      iconNode: <Menu13 />,
      key: 'finance',
    },
    {
      id: 'r10',
      code: 'system',
      title: 'Hệ thống',
      path: '/system/promotion',
      iconNode: <Menu17 />,
      key: 'system',
    },
  ],
  parentGroup: [
    {
      id: 1,
      title: '',
      key: 'empty',
      parentKey: 'system',
    },
    {
      id: 2,
      title: 'Sản phẩm',
      key: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 3,
      title: 'Đơn hàng',
      key: 'sale-order-group',
      parentKey: 'sale-management',
    },
    {
      id: 4,
      title: 'Khoá học',
      key: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 5,
      title: 'Đơn hàng',
      key: 'order-group',
      parentKey: 'sale',
    },
  ],
  parentMenu: [
    {
      id: 1,
      title: 'Video hướng dẩn',
      path: 'system/guide-video',
      iconNode: <VideoCameraOutlined />,
      key: 'guide-video',
      parentKey: 'system',
    },
    {
      id: 2,
      title: 'Ưu đãi và khuyến mãi',
      path: 'system/promotion',
      iconNode: <ReceiptPercentOutlined />,
      key: 'promotion',
      parentKey: 'system',
    },
    {
      id: 3,
      title: 'Quản lý người dùng',
      path: 'user-management/user/list',
      iconNode: <UserOutlined />,
      key: 'user',
      parentKey: 'user-management',
    },

    {
      id: 5,
      title: 'Quản lý giảng viên',
      path: 'lecturer-management/lecturer/list',
      iconNode: <UserOutlined />,
      key: 'lecturer',
      parentKey: 'lecturer-management',
    },
    {
      id: 6,
      title: 'Quản lý học viên',
      path: 'student-management/student/list',
      iconNode: <UserGroupOutlined />,
      key: 'student',
      parentKey: 'student-management',
    },
    {
      id: 7,
      title: 'Sản phẩm',
      path: 'sale-management/product/list',
      iconNode: <LockClosedOutlined />,
      key: 'product',
      parentGroupKey: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 8,
      title: 'Danh mục',
      path: 'sale-management/category/list',
      iconNode: <Menu18 />,
      key: 'category',
      parentGroupKey: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 9,
      title: 'Khóa học',
      path: 'course-management/course/list',
      iconNode: <PlayCircleOutlined />,
      key: 'course',
      parentGroupKey: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 10,
      title: 'Hoạt động khóa học',
      path: 'course-management/course-activity/list',
      iconNode: <StopCircleOutlined />,
      key: 'course-activity',
      parentGroupKey: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 11,
      title: 'Danh mục khóa học',
      path: 'course-management/course-category/list',
      iconNode: <Menu18 height={16} />,
      key: 'course-category',
      parentKey: 'course-management',
      parentGroupKey: 'course-group',
    },
    {
      id: 12,
      title: 'Đơn khóa học',
      path: 'course-management/course-order/list',
      iconNode: <DocumentChartBarOutlined />,
      key: 'course-order',
      parentKey: 'course-management',
      parentGroupKey: 'course-group',
    },
    // {
    //   id: 13,
    //   title: 'Đề xuất khuyến mãi',
    //   path: 'course-management/promotion-suggestion/list',
    //   iconNode: <Menu19 height={16} />,
    //   key: 'promotion-suggestion',
    //   parentKey: 'course-management',
    //   parentGroupKey: 'course-group',
    // },
    {
      id: 14,
      title: 'Đăng ký giảng viên',
      path: 'lecturer-management/lecturer-application/list',
      iconNode: <Menu18 />,
      key: 'lecturer-application',
      parentKey: 'lecturer-management',
    },
    {
      id: 15,
      title: 'Đơn bán hàng',
      path: 'sale-management/sale-order/list',
      iconNode: <TruckOutlined />,
      key: 'sale-order',
      parentGroupKey: 'order-group',
      parentKey: 'sale-management',
    },
    {
      id: 16,
      title: 'Đơn trả hàng bán',
      path: 'sale-management/sale-order-return/list',
      iconNode: <ArrowUturnLeftSolid />,
      key: 'sale-order-return',
      parentGroupKey: 'order-group',
      parentKey: 'sale-management',
    },
  ],
  subMenu: [
    {
      id: 3,
      name: 'Báo cáo chung',
      path: '/admin/config-affiliates/general',
      status: 'active',
      code: 's3',
      parentKey: 'config-affiliates',
      activeKeys: 'general',
    },
  ],
};
export default menu;
