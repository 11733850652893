import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  EllipsisHorizontalOutlined,
  getQueryParams,
  notification,
  PencilSquareOutlined,
  Spin,
  Tabs,
  updateQueryParams,
  XMarkOutlined,
} from 'tera-dls';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useMemo, useState } from 'react';
import { default as CourseApi } from '../../api';
import { COURSE_DETAIL_TAB_KEY } from '../../constants';
import { COURSE_URL } from '../../url';
import Device from '../Form/Device';
import Gift from '../Form/Gift';
import Material from '../Form/Material';
import Tool from '../Form/Tool';
import Description from './Description';
import Lecture from './Lecture';
import Overview from './Overview';
import { IFormModel } from '../..';
import PromotionSuggestionForm from 'pages/CourseManagement/PromotionSuggestion/containers/Form';
import StudentHomeWork from './StudentHomeWork';
import StudentProduct from './StudentProduct';
import StudentEvaluate from './StudentEvaluate';
import Student from './Student';
import Schedule from '../Form/Schedule';
import Attachment from '../Form/Attachment';

const CourseDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [openPriceSuggest, setOpenPriceSuggest] = useState<IFormModel>({
    open: false,
  });

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(['get-course-detail', id], () => CourseApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-course-summary-detail', queryParams],
    () => CourseApi.getSummaryDetail({ course_id: id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    !!id && refetch();
    refetchSummary();
  }, [id]);

  const tabItems = useMemo(() => {
    return Object.keys(COURSE_DETAIL_TAB_KEY)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{COURSE_DETAIL_TAB_KEY[key]}</span>
          <span>
            {' '}
            {!['overview', 'description'].includes(key) && (
              <Badge showZero count={summary?.[key] ?? 0} />
            )}
          </span>
        </h3>
      ),
    }));
  }, [summary]);

  const handleChangeTabs = (currentTab: string) => {
    handleUpdateQueryParams({ tab: currentTab });
  };

  const nodeContent = {
    overview: <Overview dataDetail={dataDetail} />,
    lessons_count: <Lecture dataDetail={dataDetail} />,
    attachments_count: <Attachment dataDetail={dataDetail} mode="update" />,
    schedules_count: <Schedule dataDetail={dataDetail} mode="update" />,
    description: <Description dataDetail={dataDetail} />,
    student: <Student dataDetail={dataDetail} />,
    exercise: <StudentHomeWork dataDetail={dataDetail} />,
    target: <StudentProduct dataDetail={dataDetail} />,
    evaluate: <StudentEvaluate dataDetail={dataDetail} />,
    gifts_count: <Gift mode="update" dataDetail={dataDetail} />,
    tool_product_count: <Tool mode="update" dataDetail={dataDetail} />,
    device_product_count: <Device mode="update" dataDetail={dataDetail} />,
    ingredient_product_count: (
      <Material mode="update" dataDetail={dataDetail} />
    ),
  };

  const { mutate: mutateApprove } = useMutation(
    (variables: any) => CourseApi.approve(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          queryClient.invalidateQueries(['get-course-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const { mutate: mutateReject } = useMutation(
    (variables: any) => CourseApi.reject(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleApprove = (id: number, name: string, isOnline = false): void => {
    confirm.warning({
      title: `Xác nhận duyệt khóa học ${isOnline ? 'online' : 'offline'}`,
      content: (
        <p className="break-word">
          <p>
            Bạn có chắc muốn xác nhận duyệt khóa học{' '}
            {isOnline ? 'online' : 'offline'}
          </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateApprove({ id });
      },
    });
  };

  const handleReject = (id: number, name: string, isOnline = false): void => {
    confirm.warning({
      title: `Xác nhận từ chối khóa học ${isOnline ? 'online' : 'offline'}`,
      content: (
        <p className="break-word">
          <p>
            Bạn có chắc muốn xác nhận từ chối học{' '}
            {isOnline ? 'online' : 'offline'}
          </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateReject({ id });
      },
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          queryClient.invalidateQueries(['get-course-detail']);
          notification.success({
            message: res?.msg,
          });
          navigate(COURSE_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItems = [
    ...(dataDetail?.status === 'pending'
      ? [
          {
            key: 3,
            label: <a>Đề xuất giá</a>,
            onClick: () =>
              setOpenPriceSuggest({ open: true, value: dataDetail?.id }),
          },
          {
            key: 4,
            label: <a>Duyệt</a>,
            onClick: () =>
              handleApprove(
                dataDetail?.id,
                dataDetail?.name,
                dataDetail?.classification == 'online',
              ),
          },
          {
            key: 5,
            label: <a>Từ chối</a>,
            onClick: () =>
              handleReject(
                dataDetail?.id,
                dataDetail?.name,
                dataDetail?.classification == 'online',
              ),
          },
        ]
      : []),
    ...(dataDetail?.is_active === 0
      ? [
          {
            key: '3',
            label: <span className="text-red-500">Xóa</span>,
            onClick: () => handleDelete(dataDetail?.id, dataDetail?.name),
          },
        ]
      : []),
    {
      key: '4',
      label: 'Sửa',
      onClick: () => navigate(`${COURSE_URL.update.path}/${dataDetail?.id}`),
    },
  ];

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        {dataDetail?.status === 'pending' && (
          <>
            <ButtonCommon
              onClick={() =>
                setOpenPriceSuggest({ open: true, value: dataDetail?.id })
              }
              type="success"
              className="page-header-btn"
            >
              Đề xuất giá
            </ButtonCommon>
            <ButtonCommon
              onClick={() =>
                handleApprove(
                  dataDetail?.id,
                  dataDetail?.name,
                  dataDetail?.classification == 'online',
                )
              }
              className="page-header-btn"
            >
              Duyệt
            </ButtonCommon>
            <ButtonCommon
              onClick={() =>
                handleReject(
                  dataDetail?.id,
                  dataDetail?.name,
                  dataDetail?.classification == 'online',
                )
              }
              className="page-header-btn"
              type="danger"
            >
              Từ chối
            </ButtonCommon>
          </>
        )}
        <ButtonCommon
          icon={<PencilSquareOutlined className="w-4 h-4" />}
          onClick={() =>
            navigate(`${COURSE_URL.update.path}/${dataDetail?.id}`)
          }
          className="page-header-btn"
        >
          Sửa
        </ButtonCommon>
        {dataDetail?.is_active === 0 && (
          <ButtonCommon
            icon={<XMarkOutlined className="w-4 h-4" />}
            onClick={() => handleDelete(dataDetail?.id, dataDetail?.name)}
            type="danger"
            className="page-header-btn"
          >
            Xóa
          </ButtonCommon>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  return (
    <>
      <Spin spinning={isFetching}>
        <div className="tera-page-form !gap-0">
          <div className="page-header-sticky bg-[#F3F3F9]">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div
                  className="page-header__breadcrumb-back cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowSmallLeftSolid className="h-6 w-6" />
                </div>
                <Breadcrumb
                  separator={'/'}
                  containerItemClassName="text-sm"
                  items={[
                    {
                      title: (
                        <a className="text-blue-400 hover:text-blue-600">
                          Danh sách khóa học
                        </a>
                      ),
                      onClick: () => navigate(COURSE_URL.list.path),
                    },
                    {
                      title: `Chi tiết khóa học ${dataDetail?.classification}`,
                    },
                  ]}
                />
              </div>
              <div className="page-header-v2__function">{buttonAction}</div>
            </div>
          </div>
          <div className=" page-content-v2  bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
            <Tabs
              onChange={handleChangeTabs}
              items={tabItems}
              activeKey={queryParams?.tab ?? 'overview'}
            />
            <div>{nodeContent[queryParams?.tab ?? 'overview']}</div>
          </div>
        </div>
      </Spin>
      {openPriceSuggest.open && (
        <PromotionSuggestionForm
          course_id={openPriceSuggest.value}
          open={openPriceSuggest.open}
          onClose={() => setOpenPriceSuggest({ open: false })}
        />
      )}
    </>
  );
};

export default CourseDetail;
