import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageError } from '_common/constants/message';
import useConfirm from '_common/hooks/useConfirm';

import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  Col,
  ItemType,
  PencilSquareOutlined,
  Row,
  Spin,
  XMarkOutlined,
  notification,
} from 'tera-dls';
import SaleOrderApi from '../../api';
import { SALE_ORDER_URL } from '../../url';
import CustomerInfo from './containers/CustomerInfo';
import PaymentInfo from './containers/PaymentInfo';
import ProductInfo from './containers/ProductInfo';

const SaleOrderDetail = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { id } = useParams();

  const {
    data: dataDetail,
    isLoading,
    isError,
  } = useQuery(
    ['get-sale-order-detail', id],
    () => {
      return SaleOrderApi.getDetail(id);
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const queryClient = useQueryClient();

  const { mutate: mutateSaleOrder } = useMutation(
    (variables: any) => {
      if (variables?.type === 'delete') {
        return SaleOrderApi.delete({ id: variables?.id });
      }

      if (variables?.type === 'approve') {
        return SaleOrderApi.approve({ id: variables?.id });
      }

      if (variables?.type === 'reject') {
        return SaleOrderApi.reject({
          id: variables?.id,
        });
      }
      if (variables?.type === 'complete') {
        return SaleOrderApi.complete({
          id: variables?.id,
        });
      }
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-sale-order-list']);
          queryClient.invalidateQueries(['get-sale-order-summary']);
          queryClient.invalidateQueries(['get-sale-order-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleReject = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận từ chối đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'reject',
        });
      },
    });
  };

  const handleApprove = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận duyệt đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'approve',
        });
      },
    });
  };

  const handleComplete = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận hoàn thành đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận hoàn thành đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'complete',
        });
      },
    });
  };

  const handleDelete = (item) => {
    confirm.warning({
      title: 'Xác nhận xóa đơn bán hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn hàng bán</p>
          <p>
            <b>{item?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateSaleOrder({
          id: item?.id,
          type: 'delete',
        });
      },
    });
  };

  const renderButton = (data) => {
    if (isLoading) return;

    return (
      <>
        {data?.status == 'pending' && (
          <>
            <Button
              className="page-header-btn font-light"
              type="success"
              onClick={() => handleComplete(data?.id, data.code)}
            >
              <span className="line-clamp-1">Hoàn thành</span>
            </Button>
            <Button
              className="page-header-btn font-light"
              onClick={() => handleApprove(data?.id, data.code)}
            >
              <span className="line-clamp-1">Duyệt</span>
            </Button>
            <Button
              type="danger"
              className="page-header-btn font-light"
              onClick={() => handleReject(data?.id, data.code)}
            >
              <span className="line-clamp-1">Từ chối</span>
            </Button>
          </>
        )}
        <Button
          prefix={<PencilSquareOutlined />}
          className="page-header-btn font-light"
          onClick={() => navigate(`${SALE_ORDER_URL.update.path}/${id}`)}
        >
          <span className="line-clamp-1">{'Sửa'}</span>
        </Button>
        <Button
          prefix={<XMarkOutlined />}
          type="danger"
          className="page-header-btn font-light"
          onClick={() => handleDelete(data)}
        >
          <span className="line-clamp-1">{'Xóa'}</span>
        </Button>
      </>
    );
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(SALE_ORDER_URL.list.path)}
          className="cursor-pointer text-blue-400 italic"
        >
          Danh sách đơn bán hàng
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn bán hàng',
    },
  ];

  if (isError) {
    notification.error({ message: messageError.DATA_NOT_FOUND });
    return <NoData />;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="tera-page-form">
          <div className="page-header-sticky">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <IconButton
                  icon={<ArrowSmallLeftOutlined />}
                  callBack={() => navigate(-1)}
                />
                <Breadcrumb separator=">" items={BreadcrumbItem} />
              </div>
              <div className="page-header-v2__function">
                {renderButton(dataDetail)}
              </div>
            </div>
          </div>

          <div className="page-content-v2">
            <Row className="grid-cols-12 gap-4">
              <Col className={'col-span-12 xmd:col-span-7 xl:col-span-9 '}>
                <div className="flex flex-col gap-4">
                  <CustomerInfo dataDetail={dataDetail} />
                  <ProductInfo dataDetail={dataDetail} />
                </div>
              </Col>
              <Col
                className={
                  'col-span-12 xmd:col-span-5 xl:col-span-3 xmd:row-span-2'
                }
              >
                <PaymentInfo dataDetail={dataDetail} />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SaleOrderDetail;
