import User from './User';
import UserNotification from './UserNotification/index';

const Header = () => {
  const listIcon = [<UserNotification />, <User />];
  return (
    <>
      <ul className="flex flex-row gap-2.5 pr-2.5 items-center">
        {listIcon &&
          listIcon.map((item, key) => (
            <li key={key} className="text-gray-500">
              {item}
            </li>
          ))}
      </ul>
    </>
  );
};
export default Header;
