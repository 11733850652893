import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
// import { useGoogleLogin } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import PasswordIcon from 'styles/images/Icons/PasswordIcon';
import UserIcon from 'styles/images/Icons/UserIcon';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useStores } from '_common/hooks';
import Eye from 'styles/images/Icons/Eye';
import EyeHidden from 'styles/images/Icons/EyeHidden';
import logo from 'styles/images/Icons/logo.svg';
import logo_left from 'styles/images/large_logo.png';
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  InputPassword,
  Row,
} from 'tera-dls';
import * as yup from 'yup';

const schema = yup.object().shape({
  username: yup.string().required('Bắt buộc').trim(),
  password: yup.string().required('Bắt buộc').trim(),
});

const Content = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const {
    authStore: { updateUser },
  } = useStores();

  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation(
    (variables: any) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        // const bodyParams = {
        //   access_id: res?.data?.access_id,
        // };
        // const queryParams = updateQueryParams({
        //   client_id: 'tera',
        //   req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
        // });
        updateUser(res?.data);
        navigate(`/`);
      },

      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate({ params: { ...values, type: 'admin' } });
  };
  return (
    <Row className={`min-h-screen w-[100vw] grid-cols-2 font-[Poppins]`}>
      <Col className="bg-[#F7F7F5] w-full h-full xl:block hidden">
        <div className="flex justify-center items-center w-full h-full">
          <img src={logo_left} />
        </div>
      </Col>
      <Col className="bg-white flex justify-center items-center xl:col-span-1 col-span-2">
        <div className=" w-[342px] h-[473px]">
          <div className="mb-[55px] w-full flex flex-col gap-[16px] justify-center items-center">
            <img src={logo} className="w-[270px] h-[140px]" />
            <p className="text-2xl leading-[36px] tracking-[6px] text-[#618BA7]">
              Đăng nhập
            </p>
          </div>
          <Form onSubmit={handleSubmit(handleLogin)}>
            <div className="flex flex-col gap-[24px]">
              <div className="flex flex-col gap-[18px]">
                <FormItem
                  name={'username'}
                  label=""
                  className="mb-0"
                  messages={errors?.username?.message}
                  isError={!!errors?.username}
                >
                  <Input
                    maxLength={320}
                    autoFocus
                    placeholder="Tên đăng nhập"
                    prefix={
                      <div className="grid place-items-center ">
                        <UserIcon viewBox="0 0 23 23" width={23} height={23} />
                      </div>
                    }
                    className="focus:ring-[#F3F4F6] focus:border-[#F3F4F6] !text-[12px] leading-[18px] !bg-[#F3F4F6] border-[#F3F4F6] hover:border-[#F3F4F6] py-3.5 !pl-[55px]  rounded-2xl font-normal placeholder:text-[#D1D5DB]"
                    {...register('username')}
                  />
                </FormItem>
                <FormItem
                  name={'password'}
                  label=""
                  messages={errors?.password?.message}
                  className="mb-0 "
                  childrenClassName="[&>*:last-child]:mt-2"
                  isError={!!errors?.password}
                >
                  <InputPassword
                    maxLength={100}
                    placeholder="Mật khẩu"
                    prefix={
                      <div className="grid place-items-center ">
                        <PasswordIcon
                          viewBox="0 0 21 26"
                          width={23}
                          height={23}
                          className="text-[#D1D5DB]"
                        />
                      </div>
                    }
                    className=" focus:ring-[#F3F4F6] focus:border-[#F3F4F6] !text-[12px] leading-[18px] !bg-[#F3F4F6] border-[#F3F4F6] hover:border-[#F3F4F6] py-3.5 !pl-[55px]  rounded-2xl font-normal placeholder:text-[#D1D5DB]"
                    suffixProps={{
                      className:
                        '[&>*:first-child]:h-auto [&>*:first-child]:mr-2',
                    }}
                    visibilityToggle={{ visible, onVisibleChange: setVisible }}
                    iconRender={(visible) => (
                      <div className="text-gray-700">
                        {!visible ? (
                          <Eye
                            className="size-5 cursor-pointer text-[#D1D5DB]"
                            onClick={() => setVisible(true)}
                          />
                        ) : (
                          <EyeHidden
                            onClick={() => setVisible(false)}
                            className="size-5 cursor-pointer text-[#D1D5DB]"
                          />
                        )}
                      </div>
                    )}
                    {...register('password')}
                  />
                </FormItem>
              </div>
              <div className="flex justify-center">
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  className={
                    'text-[10px] leading-[1.125rem] rounded-[15px] bg-[#1975A7] hover:bg-[#1975A7] px-[20px] py-[10px] shadow-lg'
                  }
                >
                  Đăng nhập
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Content;
