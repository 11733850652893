import CardDetail from '_common/component/CardDetail';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { formatCurrency } from 'tera-dls';

const PaymentInfo = ({ dataDetail }) => {
  const [discountValue, setDiscountValue] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const totalData = dataDetail?.final_total;
  const taxAmount = Number(get(dataDetail, 'tax_amount', 0));
  const finalTotal = Number(get(dataDetail, 'final_total', 0));
  const shippingAmount = Number(get(dataDetail, 'shipping_charges', 0));

  useEffect(() => {
    if (!totalData) return;
    const discountType = get(dataDetail, 'discount_type');
    const discountAmount = Number(get(dataDetail, 'discount_amount', 0));
    const totalBeforeTax = Number(get(dataDetail, 'total_before_tax', 0));

    let discountValue = discountAmount;

    if (discountType === 'percentage') {
      discountValue = Number(totalBeforeTax) * (Number(discountAmount) / 100);
    }

    setTotalPrice(finalTotal - shippingAmount - taxAmount + discountValue);

    setDiscountValue(discountValue);
  }, [totalData]);

  const renderInfo = [
    {
      title: 'Tạm tính',
      value: formatCurrency(totalPrice),
    },
    {
      title: 'Chiết khấu',
      value: `${formatCurrency(discountValue)}`,
    },
    {
      title: 'Phí vận chuyển',
      value: `${formatCurrency(shippingAmount)}`,
    },
    {
      title: 'Lợi nhuận',
      value: formatCurrency(get(dataDetail, 'final_profit', 0)),
    },
    {
      title: 'Thành tiền',
      value: formatCurrency(finalTotal),
    },
    {
      title: 'Hình thức thanh toán',
      value: '',
    },
  ];
  return (
    <CardDetail title="Thanh toán" className=" shadow-none">
      {renderInfo.map(
        ({ title, value }, index) =>
          title && (
            <div className="flex items-start pb-2.5" key={index}>
              <div className="detail-key">{title}</div>
              <div className="detail-value">{value}</div>
            </div>
          ),
      )}
    </CardDetail>
  );
};

export default PaymentInfo;
