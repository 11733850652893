import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SaleOrderEndpoint = `${endpoint}/bake-teach/order`;
const SaleOrderApi = {
  getList: async (params) =>
    await api
      .get(`${SaleOrderEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${SaleOrderEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${SaleOrderEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${SaleOrderEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${SaleOrderEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  approve: async ({ id }) =>
    await api
      .put(`${SaleOrderEndpoint}/approve/${id}`)
      .then((res) => res?.data),
  complete: async ({ id }) =>
    await api
      .put(`${SaleOrderEndpoint}/complete/${id}`)
      .then((res) => res?.data),
  reject: async ({ id }) =>
    await api.put(`${SaleOrderEndpoint}/reject/${id}`).then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${SaleOrderEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default SaleOrderApi;
