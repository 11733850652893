import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
// import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
// import { StatusRender } from 'pages/Purchase/containers/PurchaseManagement';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { messageError } from '_common/constants/message';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  PaginationProps,
  Tabs,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import SaleOrderApi from './api';
import { ORDER_STATUS } from './constants';
import Filter from './containers/Filter';
import Header from './containers/Header';
import SaleOrderTable from './containers/Table';
import { SALE_ORDER_URL } from './url';

const SaleOrder = () => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SALE_ORDER_URL.list.path}${paramString}`);
  };

  const dataQuery = _.omit(queryParams, ['status']);

  const {
    data: listSaleOrder,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-sale-order-list', queryParams],
    () => {
      const params = {
        ...dataQuery,
        page: 1,
        limit: 10,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && {
            status: queryParams?.status,
          }),
      };
      return SaleOrderApi.getList(params);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { data: summary, refetch: fetchSummary } = useQuery(
    ['get-sale-order-summary', queryParams],
    () =>
      SaleOrderApi.getSummaryList({
        ...dataQuery,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && {
            status: queryParams?.status,
          }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    refetch();
    fetchSummary();
  }, []);

  const { mutate: mutateSaleOrder, isLoading: loadingUpdateStatus } =
    useMutation(
      (variables: any) => {
        if (variables?.type === 'delete') {
          return SaleOrderApi.delete({ id: variables?.id });
        }

        if (variables?.type === 'approve') {
          return SaleOrderApi.approve({ id: variables?.id });
        }

        if (variables?.type === 'reject') {
          return SaleOrderApi.reject({
            id: variables?.id,
          });
        }
        if (variables?.type === 'complete') {
          return SaleOrderApi.complete({
            id: variables?.id,
          });
        }
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            queryClient.invalidateQueries(['get-sale-order-list']);
            queryClient.invalidateQueries(['get-sale-order-summary']);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError(error: any) {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleChangeTab = (key) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateFilter({ page: page, limit: pageSize });
  };

  const handleFilter = (values) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateFilter({ keyword: value?.keyword, page: 1 });
  };

  const handleDelete = (item) => {
    confirm.warning({
      title: 'Xác nhận xóa đơn bán hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn hàng bán</p>
          <p>
            <b>{item?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateSaleOrder({
          id: item?.id,
          type: 'delete',
        });
      },
    });
  };

  const handleComplete = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận hoàn thành đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận hoàn thành đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'complete',
        });
      },
    });
  };

  const handleApprove = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận duyệt đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'approve',
        });
      },
    });
  };

  const handleReject = (id: number, code: string) => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận từ chối đơn bán hàng </p>
          <p>
            <b>{code}</b> này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSaleOrder({
          id,
          type: 'reject',
        });
      },
    });
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const result = [];

    result.push({
      key: 1,
      label: 'Xem',
      onClick: () => navigate(`${SALE_ORDER_URL.detail.path}/${item?.id}`),
    });
    if (item?.status == 'pending') {
      result.push({
        key: 2,
        label: 'Duyệt',
        onClick: () => handleApprove(item?.id, item.code),
      });

      result.push({
        key: 3,
        label: 'Từ chối',
        onClick: () => handleReject(item?.id, item.code),
      });

      result.push({
        key: 4,
        label: 'Hoàn thành',
        onClick: () => handleComplete(item?.id, item.code),
      });
    }

    result.push({
      key: 5,
      label: 'Sửa',
      onClick: () => navigate(`${SALE_ORDER_URL.update.path}/${item?.id}`),
    });

    result.push({
      key: 6,
      label: <span className="text-red-500">{'Xóa'}</span>,
      onClick: () => handleDelete(item),
    });

    return result;
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(ORDER_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{ORDER_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  if (isError)
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="DANH SÁCH ĐƠN BÁN HÀNG"
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => navigate(SALE_ORDER_URL.create.path)}
        bottomContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Header onSearch={handleSearch} />}
      >
        <SaleOrderTable
          data={listSaleOrder?.data ?? []}
          loading={isLoading || loadingUpdateStatus}
          renderItemActions={renderItemActions}
          rowKey={'id'}
          pagination={{
            onChange: handleChangePage,
            total: listSaleOrder?.total || 0,
            current: listSaleOrder?.current_page,
            pageSize: listSaleOrder?.per_page || 10,
            to: listSaleOrder?.to,
            from: listSaleOrder?.from || 10,
          }}
        />
      </HeaderViewListV2>

      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default observer(SaleOrder);
