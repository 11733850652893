import { convertClassNameToInlineStyle } from '_common/component/CkEditor';
import FormTera from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InformationView from './InformationView';

const Information = ({}, ref) => {
  const form = useForm({ mode: 'onChange' });
  const {
    course: { setInformation, nextStep, information: informationData },
  } = useStores();
  const [image, setImage] = useState<any>();

  const { type } = useParams();
  const typeFile = form.watch('link_url_type');
  const limited = form.watch('have_limit_student');
  const price = form.watch('price');

  useEffect(() => {
    if (informationData) {
      form.reset({
        ...informationData,
      });
      informationData?.file_upload && setImage(informationData?.file_upload);
    } else {
      type === 'offline' && form.setValue('link_url_type', 'uploaded_video');
      form.setValue('link_url_type', null);
      form.setValue('limit_student', 1);
      form.setValue('is_active', true);
    }
  }, [informationData, type]);

  const handleSubmitForm = (values) => {
    setInformation({
      ...values,
      file_upload: image,
      information: convertClassNameToInlineStyle(
        (window as any).editor ? (window as any).editor.getData() : null,
      ),
    });
    nextStep();
  };

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        form.handleSubmit(handleSubmitForm)();
      },
    };
  });

  // const {
  //   formState: { isDirty },
  // } = form;
  // const { id } = useParams();

  // const {
  //   data: detail,
  //   refetch: fetchData,
  //   isLoading,
  // } = useQuery(['get-lecturer-detail', id], () => CourseApi.getDetail(id), {
  //   staleTime: 300000,
  //   cacheTime: 300000,
  //   enabled: !!id,
  // });

  // useEffect(() => {
  //   id && fetchData();
  // }, [id]);

  // usePrompt(
  //   <p className="break-word">
  //     <p>{messageWarning.WARNING_EXIT_1}</p>
  //     <p>{messageWarning.WARNING_EXIT_2}</p>
  //   </p>,
  //   isDirty,
  // );

  return (
    <FormTera form={form} isLoading={false}>
      <InformationView
        {...{
          form,
          image,
          setImage,
          type,
          typeFile,
          limited,
          price,
        }}
      />
    </FormTera>
  );
};

export default observer(forwardRef(Information));
