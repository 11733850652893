import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import React, { useRef, useState } from 'react';
import imageError from 'styles/images/video-clip-deny.png';
import customTwMerge from 'tailwind-merge.config';
import { VideoCameraOutlined } from 'tera-dls';
import ModalPlayVideo from './ModalPlayVideo';

// type Isize = 'small' | 'normal';
const NormalVideo = (props) => {
  const {
    url,
    isView = false,
    isReview = true,
    size = 'normal',
    ...restProps
  } = props;
  const [duration, setDuration] = useState<any>(0);
  const [errorThumbnail, setErrorThumbnail] = useState<boolean>(false);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const { form } = useTeraForm();
  const isSmallSize = size === 'small';
  const videoRef = useRef(null);

  const [loading, setLoading] = useState(() => isView);

  const handleLoadedMetadata = (event) => {
    const videoDuration = event.target.duration;
    setDuration(videoDuration);
    form?.setValue('updated_video_duration', videoDuration);
    setLoading(false);
  };

  if (errorThumbnail) {
    return (
      <>
        <img
          className="h-full flex justify-center"
          {...restProps}
          src={imageError}
        />
      </>
    );
  }
  if (isView) {
    return (
      <>
        <div
          className={customTwMerge(
            'rounded-[10px] size-[82px] overflow-hidden cursor-pointer bg-gray-200 relative',
            isSmallSize && '!size-[40px]',
            (!isReview || errorThumbnail) &&
              'cursor-default pointer-events-none',
          )}
          onClick={() => isReview && !errorThumbnail && setOpenReview(true)}
        >
          {!loading && isReview && (
            <div className="absolute flex justify-center items-center size-full">
              <div
                style={{ clipPath: 'polygon(0 0, 0% 100%, 98% 52%)' }}
                className={customTwMerge(
                  'size-[28px] bg-white drop-shadow-sm',
                  isSmallSize && 'size-[12px] mb-3',
                )}
              />
            </div>
          )}
          {loading && (
            <div className="absolute flex justify-center items-center size-full bg-[#ffffff63]">
              <div
                className={customTwMerge(
                  'size-[40px] animate-spin border-2 !border-b-white rounded-full border-blue-700',
                  isSmallSize && '!size-[12px] !border-1',
                )}
              />
            </div>
          )}
          <video
            key={url}
            ref={videoRef}
            src={url}
            onError={() => {
              setErrorThumbnail(true);
              setLoading(false);
            }}
            className={customTwMerge(
              'h-[66px] w-full',
              isSmallSize && '!w-[40px] !h-[28px]',
            )}
            onLoadedMetadata={handleLoadedMetadata}
          />
          <div
            className={customTwMerge(
              'px-[8px] bg-gray-400 text-[10px] flex justify-between',
              isSmallSize && '!justify-center',
            )}
          >
            <VideoCameraOutlined
              className={customTwMerge(
                'size-[15px] text-white',
                isSmallSize && '!size-[12px]',
              )}
            />
            {!isSmallSize && (
              <span className="text-white">
                {Math.floor(duration / 60)}:{Math.floor(duration % 60)}
              </span>
            )}
          </div>
        </div>
        {openReview && (
          <ModalPlayVideo
            open={openReview}
            onClose={() => setOpenReview(false)}
            url={url}
            type={'uploaded_video'}
          />
        )}
      </>
    );
  }
  return (
    <video
      src={url}
      onError={() => {
        setErrorThumbnail(true);
        setLoading(false);
      }}
      className={customTwMerge(
        'h-[700px] w-[1000px]',
        isSmallSize && '!w-[40px] !h-[28px]',
      )}
      controls
    />
  );
};

export default React.memo(NormalVideo);
