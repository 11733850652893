const YoutobeIcon = (props) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6466 1.75469C15.5549 1.41553 15.3758 1.10634 15.1274 0.857893C14.879 0.609445 14.5698 0.430413 14.2306 0.338625C12.989 0 7.99189 0 7.99189 0C7.99189 0 2.99451 0.01025 1.75289 0.348875C1.41373 0.440669 1.10454 0.61971 0.856102 0.868169C0.607665 1.11663 0.428651 1.42584 0.336887 1.765C-0.0386755 3.97113 -0.184363 7.33275 0.347199 9.45063C0.438973 9.78978 0.617991 10.099 0.866427 10.3474C1.11486 10.5959 1.42405 10.7749 1.7632 10.8667C3.00482 11.2053 8.00208 11.2053 8.00208 11.2053C8.00208 11.2053 12.9993 11.2053 14.2408 10.8667C14.58 10.7749 14.8892 10.5959 15.1376 10.3474C15.3861 10.099 15.5651 9.78979 15.6569 9.45063C16.053 7.24138 16.1751 3.88181 15.6466 1.75469Z"
      fill="#FF0000"
    />
    <path
      d="M6.39844 8.00342L10.5439 5.6023L6.39844 3.20117V8.00342Z"
      fill="white"
    />
  </svg>
);
export default YoutobeIcon;
