import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_QUANTITY } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { observer } from 'mobx-react-lite';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  formatCurrency,
  Image,
  notification,
  PaginationProps,
  PlusCircleOutlined,
  TrashOutlined,
} from 'tera-dls';
import CourseGiftApi from '../../api/Gift';
import ProductModal from './ProductModal';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';

interface IProps {
  mode?: 'create' | 'update';
  dataDetail?: any;
}
const Gift = ({ mode = 'create', dataDetail }: IProps, ref) => {
  const confirm = useConfirm();
  const {
    course: { gift, setGift, nextStep },
  } = useStores();

  const [data, setData] = useState<any>([]);
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const queryClient = useQueryClient();
  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const isUpdateMode = mode == 'update';

  const {
    data: list,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-gift-list', dataDetail?.id],
    () => CourseGiftApi.getList({ course_id: dataDetail?.id, ...params }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    dataDetail?.id && fetchData();
  }, [dataDetail?.id]);

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        nextStep();
      },
    };
  });

  useEffect(() => {
    if (isUpdateMode && !!list?.data) {
      setData(
        list?.data?.map((i) => ({ ...i, ...(i?.product ?? {}), key: i?.id })),
      );
      return;
    }
    gift?.length > 0 && setData(gift);
  }, [gift, list?.data, isUpdateMode]);

  const { mutate: mutateUpdate } = useMutation(
    (variables: any) => CourseGiftApi.update(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-gift-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseGiftApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-gift-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xoá sản phẩm quà tặng',
      content: (
        <>
          <p>Bạn có chắc muốn xóa sản phẩm quà tặng</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        if (isUpdateMode) {
          mutateDelete({ id });
          return;
        }
        setData((prev) => {
          const data = prev?.filter((i) => i?.id !== id);
          setGift(data);
          return data;
        });
      },
    });
  };

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 150,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 150,
      editable: true,
      inputProps: {
        min: 1,
        max: MAXIMUM_QUANTITY,
      },
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    ...(isUpdateMode
      ? [
          {
            title: '',
            dataIndex: OPERATION_KEY,
            width: 50,
            fixed: 'right',
          },
        ]
      : [
          {
            title: '',
            width: 50,
            fixed: 'right',
            render: (record: any) => (
              <div className="flex justify-center">
                <TrashOutlined
                  className="text-red-500 size-5 cursor-pointer"
                  onClick={() =>
                    handleDelete(
                      isUpdateMode ? record?.key : record?.id,
                      record?.name,
                    )
                  }
                />
              </div>
            ),
          },
        ]),
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="space-y-2.5">
        <h4 className="text-blue-500 font-medium mb-5 ">Thêm quà tặng</h4>
        <div className="flex justify-end">
          <Button
            className="font-normal flex gap-1"
            onClick={() => setOpenProduct(true)}
          >
            <PlusCircleOutlined className="w-4" />{' '}
            <span>Thên nhiều sản phẩm</span>
          </Button>
        </div>
        <TableTera
          rowKey={'id'}
          data={data}
          mode={isUpdateMode ? 'editable-row' : 'editable-cell'}
          editable={{
            onValuesChange(record, recordList) {
              if (isUpdateMode) {
                mutateUpdate({
                  id: record?.key,
                  params: {
                    quantity: record?.quantity,
                  },
                });
                return;
              }
              setGift(recordList);
            },
          }}
          loading={isUpdateMode && isFetching}
          pagination={{
            ...(isUpdateMode && {
              onChange: handleChangePage,
              total: list?.total || 0,
              current: list?.current_page,
              pageSize: list?.per_page || 10,
              to: list?.to,
              from: list?.from || 10,
            }),
          }}
          className="center-table"
          columns={columns}
        />
      </div>
      {openProduct && (
        <ProductModal
          open={openProduct}
          onClose={() => setOpenProduct(false)}
          selectedValue={data}
          mode={mode}
          courseId={dataDetail?.id}
          type={'gifts'}
          onSubmit={(values) => {
            setData((prev = []) => {
              const data = [
                ...prev,
                ...values?.map((i) => ({ ...i, quantity: 1 })),
              ];
              setGift(data);
              return data;
            });
          }}
        />
      )}
    </>
  );
};

export default observer(forwardRef(Gift));
