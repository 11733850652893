import CardDetail from '_common/component/CardDetail';

import TableTera from '_common/dof/TableTera';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import customTwMerge from 'tailwind-merge.config';
import { formatCurrency, Image } from 'tera-dls';

const ProductInfo = ({ dataDetail }) => {
  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{record?.name_product ?? val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 120,
      render: (val, record) => record?.name_category ?? val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 150,
      render: (price) => formatCurrency(Number(price)),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 120,
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
  ];

  return (
    <CardDetail title="Thông tin sản phẩm" className="h-full">
      <TableTera columns={columns} data={dataDetail?.products || []} />
    </CardDetail>
  );
};

export default ProductInfo;
