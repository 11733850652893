import ImageBackground from '_common/component/ImageBackground';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import UploadFiles from '_common/dof/UploadFiles';
import { IFileUpload } from '_common/interface';
import { useEffect, useState } from 'react';
import { Image, PlusOutlined, XCircleSolid } from 'tera-dls';
type ListImageProductProps = {
  defaultProductImages?: any[];
};
const ListImageProduct = ({ defaultProductImages }: ListImageProductProps) => {
  const {
    form: { setValue, watch },
  } = useTeraForm();
  const remove_images: number[] = watch('remove_images') || [];
  const watchImages: any[] = watch('images');
  const watchThumbnail: string = watch('thumbnail');
  const [thumbnail, setThumbnail] = useState<IFileUpload>({});
  const [displayImages, setDisplayImages] = useState<IFileUpload[]>([]);

  useEffect(() => {
    watchThumbnail && setThumbnail({ url: watchThumbnail });
  }, [watchThumbnail]);

  useEffect(() => {
    if (!!defaultProductImages?.length) {
      const mapImages = defaultProductImages.map((image) => ({
        id: image.id,
        name: image.id,
        url: image.image_url,
      }));
      setDisplayImages(mapImages);
    } else {
      setDisplayImages([]);
    }
  }, [defaultProductImages]);

  const handleRemoveListImage = (id: number) => {
    const filterListImage = displayImages.filter((item) => item.id !== id);
    setDisplayImages(filterListImage);
    if (defaultProductImages) {
      const indexImage = defaultProductImages.findIndex(
        (item) => item.id === id,
      );
      indexImage > -1 && setValue('remove_images', [...remove_images, id]);
    }
  };

  const handleUploadThumbnail = (file: IFileUpload) => {
    setThumbnail(file);
    setValue('thumbnail', file ? file?.url : null);
  };
  const handleUploadListImage = (file: IFileUpload, files: IFileUpload[]) => {
    setDisplayImages(files);
    if (watchImages?.length > 0) {
      setValue('images', [...watchImages, file]);
    } else {
      setValue('images', [file]);
    }
  };

  const nodeButtonDelete = (fn: () => void) => {
    return (
      <>
        <XCircleSolid
          className="cursor-pointer absolute z-50 w-6 right-[-10px] top-[-8px] fill-red-500"
          onClick={fn}
        />
        <div className="absolute -right-0.5 w-2 h-2 bg-white z-30" />
      </>
    );
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div>
        <h3 className="mb-2.5">Ảnh bìa</h3>
        <ImageBackground
          onChange={handleUploadThumbnail}
          value={thumbnail}
          isShowBtnDelete
          imageClassName="h-[100px] w-[100px] object-cover"
        />
      </div>
      <div>
        <h3 className="mb-2.5">Ảnh chính</h3>
        <div className="flex gap-2.5 flex-wrap">
          {displayImages.map((image, key) => (
            <div className="relative" key={image.id || key}>
              {nodeButtonDelete(() => handleRemoveListImage(image.id))}
              <Image
                key={image.id}
                src={image.url}
                imageClassName="h-[100px] w-[100px] object-cover"
                borderRadius={10}
                alt={image.name}
              />
            </div>
          ))}
          <UploadFiles
            fileList={displayImages}
            onReceiveFiles={handleUploadListImage}
            maxSize={5}
            object_id="product_images"
            object_key="product"
            folder="product"
            isView={false}
            isCount={false}
            accept=".png, .jpeg, .gif, .jpg"
          >
            <button
              className="w-[100px] h-[100px] border border-dashed border-gray-200 bg-gray-50 rounded-[10px] flex items-center justify-center"
              type="button"
            >
              <PlusOutlined className="w-8 h-8 text-green-500" />
            </button>
          </UploadFiles>
        </div>
      </div>
    </div>
  );
};

export default ListImageProduct;
