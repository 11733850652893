import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  TableProps,
  TableRowSelection,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import { SALE_ORDER_URL } from '../../url';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';

interface IProps extends TableProps {
  rowSelection?: TableRowSelection<unknown>;
  data: any;
  loading?: boolean;
  renderItemActions?: (record) => DropdownItem[];
  [key: string]: any;
}

const SaleOrderTable = (props: IProps) => {
  const { renderItemActions } = props;
  const navigate = useNavigate();

  const columns: any = [
    {
      title: 'Mã đơn',
      dataIndex: 'code',
      width: 150,
      render: (code, record) => (
        <p
          className="text-blue-600 cursor-pointer inline-block"
          onClick={() =>
            navigate(`${SALE_ORDER_URL.detail.path}/${record?.id}`)
          }
        >
          {code}
        </p>
      ),
    },
    {
      title: 'Học viên',
      dataIndex: 'student',
      width: 150,
      render: (val) => val?.full_name,
    },

    {
      title: 'Giá trị đơn hàng',
      dataIndex: 'final_total',
      width: 150,
      render: (final_total) => formatCurrency(Number(final_total)),
    },

    {
      title: 'Ngày đặt hàng',
      dataIndex: 'order_date',
      width: 150,
      render: (date) => formatDate(date, DATE_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (val) => (
        <Tag color={ORDER_STATUS_COLOR[val]}>{ORDER_STATUS[val]}</Tag>
      ),
    },
    {
      title: '',
      width: 60,
      dataIndex: 'operation',
      fixed: 'right',
      render: (_, record) => {
        const items = renderItemActions(record);
        return (
          <ActionDropdown
            dropdownItems={items}
            trigger="click"
            containerClassName="max-h-[500px]"
          />
        );
      },
    },
  ];

  return (
    <>
      <TableTera {...props} columns={columns} rowKey={'id'} />
    </>
  );
};

export default SaleOrderTable;
